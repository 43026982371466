import { useRef, useState, useCallback, useEffect } from "react";
import { GetCourseDetails, GetLearningUrl, LearningCourse } from "../../handler/coursehandler";

const packageJson = require('../../../package.json');
interface InnerBannerProps {
    courseId: number;
}

const Learning: React.FC<InnerBannerProps> = ({ courseId }) => {
    const [videoShow, setVideoShow] = useState("");
    const [isTrayVisible, setIsTrayVisible] = useState(false);
    const [isTrayHidden, setIsTrayHidden] = useState(false);
    const playerRef = useRef<HTMLVideoElement | null>(null);
    const [isVideoPlay, setIsVideoPlay] = useState(true);
    const [userInformationId, setUserInformationId] = useState(0);
    const [selectedCourse, setSelectedCourse] = useState(
        {
            "courseVideoId": 1,
            "courseVideoName": "",
            "courseServerName": "BodyLanguage_Chapter_1",
            "videoDurationInSeconds": 0,
            "currentVideoDurationInSeconds": 0,
            "videoPercentage": 0
        },
    );
    const [courseLearning, setCourseLearning] = useState(
        {
            "courseId": 0,
            "courseName": "",
            "serverName": "",
            "courseDescription": "",
            "packageId": 0,
            "coursePrice": 0,
            "percentage": 0,
            "currentVideoId": 0,
            "courseVideos": [
                {
                    "courseVideoId": 0,
                    "courseVideoName": "",
                    "courseServerName": "",
                    "totalVideoDurationInSeconds": 0,
                    "currentVideoDurationInSeconds": 0,
                    "courseId": 0,
                    "videoPercentage": 0
                },
            ]
        }
    )

    const onReady = useCallback(async (userInformationId: number) => {

        var currentVideoDurationInSeconds = await getCourseDetails(userInformationId);
        setTimeout(() => {
            if (playerRef.current) {
                playerRef.current.currentTime = currentVideoDurationInSeconds;
            }
        }, 10);
    }, []);

    const runOnce = useRef(false);
    useEffect(() => {
        if (!runOnce.current) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            window.scrollTo(0, 0)
            setUserInformationId(userProfile.userInformationId);
            onReady(userProfile.userInformationId);
            runOnce.current = true;
        }
    }, [onReady]);

    const getCourseDetails = async (userInformationId: number) => {

        var response = await GetCourseDetails(courseId, userInformationId);
        if (response.statusCode === 200) {
            setCourseLearning(response.data.courses);
            if (response.data.courses.courseVideos) {
                if (response.data.courses.currentVideoId > 0) {
                    var currentRunningCourseVideo = response.data.courses?.courseVideos.find(data => data.courseVideoId === response.data.courses.currentVideoId)
                }
                else {
                    var currentRunningCourseVideo = response.data.courses?.courseVideos[0];
                }
                setSelectedCourse(currentRunningCourseVideo)
                learningUrl(response.data.courses.serverName, currentRunningCourseVideo.courseServerName)
                await LearningCourse(
                    {
                        "userInformationId": userInformationId,
                        "courseId": courseId,
                        "courseVideoId": currentRunningCourseVideo.courseVideoId,
                        "videoTiming": currentRunningCourseVideo.currentVideoDurationInSeconds
                    }, userInformationId
                )
                return currentRunningCourseVideo.currentVideoDurationInSeconds;
            }
        }
    }

    const learningUrl = async (serverName: string, courseServerName: string) => {
        var response = await GetLearningUrl({
            "courseName": serverName,
            "courseServerName": courseServerName
        })
        if (response.statusCode === 200) {
            setVideoShow(packageJson.videoApiUrl +
                serverName +
                "/" +
                courseServerName +
                ".mp4?" +
                packageJson.awsAccessKeyId +
                response.data.videoPrivateKey);
        }
    }

    const changeVideo = async (courseVideo: any) => {
        if (selectedCourse.courseVideoId != courseVideo.courseVideoId) {
            setSelectedCourse(courseVideo);
            await LearningCourse(
                {
                    "userInformationId": userInformationId,
                    "courseId": courseId,
                    "courseVideoId": courseVideo.courseVideoId,
                    "videoTiming": courseVideo.currentVideoDurationInSeconds
                }, userInformationId
            )
            learningUrl(courseLearning.serverName, courseVideo.courseServerName);
        }
        else {
            if (playerRef.current) {
                if (playerRef.current.paused) {
                    playerRef.current.play();
                    setIsVideoPlay(true);
                } else {
                    playerRef.current.pause();
                    setIsVideoPlay(false);
                }
            }
        }
    }

    const UpdateLearningCourse = async () => {
        await LearningCourse(
            {
                "userInformationId": userInformationId,
                "courseId": courseId,
                "courseVideoId": selectedCourse.courseVideoId,
                "videoTiming": playerRef.current?.currentTime
            }, userInformationId
        )
    }

    return (
        <>
            <div style={{
                backgroundColor: "#343434",
            }}>
                <h6
                    style={{
                        color: "#f4f5f7",
                        fontSize: "25px",
                        marginBottom: "20px",
                        textAlign: "center",
                        paddingTop: "10px",
                        fontWeight: "bold"
                    }}
                >
                    {selectedCourse.courseVideoName}
                </h6>
                <div
                    className="learningPannel"
                    style={{
                        position: "relative",
                        fontFamily: "'Roboto', sans-serif",
                        backgroundColor: "#f4f5f7",
                        overflow: "hidden",
                        padding: "20px 0"
                    }}
                >
                    {/* Mobile Toggle Button */}
                    {
                        !isTrayVisible && <>
                            <button
                                onClick={() => {
                                    setTimeout(() => {
                                        setIsTrayHidden(!isTrayHidden);
                                    }, 100);
                                    setIsTrayVisible(!isTrayVisible);

                                }}
                                style={{
                                    position: "absolute",
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "50px",
                                    padding: "10px 20px",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                                    fontWeight: "bold",
                                    marginLeft: "10px"
                                }}
                            >
                                📚 Chapters ({courseLearning.courseName})
                            </button>
                        </>
                    }

                    {/* Left Tray (Overlay on Mobile) */}
                    <div
                        className="learningLeftPannel"
                        style={{
                            top: 0,
                            left: 0,
                            backgroundColor: "#ffffff",
                            boxShadow: "2px 0 10px rgba(0, 0, 0, 0.2)",
                            transform: isTrayVisible ? "translateX(0)" : "translateX(-100%)",
                            transition: "transform 0.3s ease-in-out",
                            zIndex: 999,
                        }}
                        hidden={!isTrayHidden}
                    >

                        <div style={{ background: "#343434", padding: "5px 2px" }}>
                            <button
                                onClick={() => {
                                    setTimeout(() => {
                                        setIsTrayHidden(!isTrayHidden);
                                    }, 100);
                                    setIsTrayVisible(!isTrayVisible);
                                }}
                                style={{
                                    backgroundColor: "#e74c3c",
                                    color: "#fff",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: "10px",
                                    fontSize: "1rem",
                                    display: "block",
                                    width: "100%",
                                    borderRadius: "5px",
                                    margin: "10px 0 0 0"
                                }}
                            >
                                Close
                            </button>
                            <h6
                                style={{
                                    fontSize: "25px",
                                    color: "#f4f5f7",
                                    margin: "10px 0px",
                                    textAlign: "center",
                                }}
                            >
                                🎓 Course Chapters
                            </h6>
                        </div>

                        <div className="learningLeftPannelData">
                            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                                {courseLearning.courseVideos.map((course) => (
                                    <li
                                        key={course.courseVideoId}
                                        onClick={() => {
                                            changeVideo(course);
                                        }}
                                        style={{
                                            padding: "15px 20px",
                                            marginBottom: "15px",
                                            cursor: "pointer",
                                            backgroundColor:
                                                selectedCourse.courseVideoId === course.courseVideoId
                                                    ? "#007bff"
                                                    : "#f8f9fa",
                                            color:
                                                selectedCourse.courseVideoId === course.courseVideoId
                                                    ? "#fff"
                                                    : "#495057",
                                            border: "1px solid #ddd",
                                            borderRadius: "8px",
                                            fontWeight:
                                                selectedCourse.courseVideoId === course.courseVideoId
                                                    ? "bold"
                                                    : "normal",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                            transition:
                                                "background-color 0.3s ease, color 0.3s ease",
                                        }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            {course.courseVideoName}
                                            {
                                                selectedCourse.courseVideoId == course.courseVideoId
                                                    ?
                                                    <>
                                                        {
                                                            isVideoPlay ?
                                                                <><span>⏸️</span></> : <><span>▶️</span></>
                                                        }
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Video Section */}
                    <div
                        className="learningRightPannel"
                        style={{
                            textAlign: "center",
                            alignContent: "center",
                            padding: "50px 20px",
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "center",
                            alignItems: "center",
                            background: "#f4f5f7"
                        }}
                    >
                        <video
                            key={videoShow}
                            ref={playerRef}
                            autoPlay
                            controls
                            controlsList="nodownload"
                            onContextMenu={(e) => e.preventDefault()}
                            style={{
                                position: "relative",
                                display: "inline-block",
                                width: "100%",
                                border: "1px solid #007bff",
                                overflow: "hidden",
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                background: "black"
                            }}
                            onTimeUpdate={() => {
                                if (playerRef.current) {
                                    const currentTime = Math.floor(playerRef.current.currentTime);

                                    // Trigger the function if the time is a multiple of 60 seconds
                                    if (currentTime % 60 === 0 && currentTime !== 0) {
                                        UpdateLearningCourse();
                                    }
                                }
                            }}
                        >
                            <source
                                src={videoShow}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Learning;