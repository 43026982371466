import "../../Dashboard/Design/Dashboard.css"
import { useEffect, useRef, useState } from "react";
import { dashboard } from "../../handler/LoginAndSingupHandler";
import { Button } from "@mui/material";
import courseImage from "src/Common/CourseImage";

const CourseOrders = () => {
    const runOnce = useRef(false);
    type Course = {
        courseId: number,
        courseName: string,
        packageId: number,
        courseDescription: string,
        coursePrice: number
    }
    const [packages, setPackages] = useState<Course[]>([
        {
            courseId: 0,
            courseName: "",
            packageId: 0,
            courseDescription: "",
            coursePrice: 0
        }
    ]);
    const getCourses = async () => {
        var response = await dashboard(2);
        if (response.statusCode === 200) {
            setPackages(response.data.dashboard.courses);
        }
    }
    const [showPackageInfo, setShowPackageInfo] = useState(true);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setSelectedCourses(typeof value === "string" ? value.split(",") : value);
    };
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);

    const handleAddToCart = (course: Course) => {
        setSelectedCourses((prevSelectedCourses) => [...prevSelectedCourses, course]);
        console.warn(selectedCourses);
    };
    const handleRemoveFromCart = (course: Course) => {
        setSelectedCourses((prevSelectedCourses) =>
            prevSelectedCourses.filter((c) => c.courseId !== course.courseId)
        );
        console.warn(selectedCourses);
    }
    const [packageId, setPackageId] = useState(0);
    useEffect(() => {
        if (runOnce.current === false) {
            runOnce.current = true;
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setPackageId(userProfile.packageId);
            getCourses();
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        <>
            <section style={{
                width: "95%",
                height: "85%",
                marginTop: "10px",
                fontFamily: "Arial, sans-serif",
                background: "#CECECE"
            }}>
                <section style={{
                    background: "#E0E0E0",
                    width: "80%",
                    margin: "20px auto",
                    fontFamily: "Georgia, serif",
                    position: "relative"
                }}>
                    {/* Cart Icon */}
                    <div style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: "default"
                    }}>
                        <i
                            className="fas fa-shopping-cart" // Replace with a proper icon class from your library
                            style={{ fontSize: "20px", color: "#555", marginRight: "5px" }}
                        ></i>
                        <span style={{ fontSize: "14px", color: "#333" }}>{selectedCourses.length}</span>
                    </div>

                    {/* Purchase Order Heading */}
                    <h1 style={{
                        textAlign: "center",
                        color: "#444",
                        marginBottom: "15px",
                        fontWeight: "bold"
                    }}>
                        Purchase Order
                    </h1>

                    {/* Cart Details */}
                    <div style={{
                        marginBottom: "30px",
                        textAlign: "center",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "20px"
                    }}>
                        <h3 style={{
                            margin: "10px 0",
                            color: "#444",
                            fontSize: "18px",
                            fontWeight: "normal"
                        }}>
                            Total Price: ₹{selectedCourses.reduce((sum, course) => sum + course.coursePrice, 0)}
                        </h3>
                        <Button
                            style={{
                                padding: "10px 20px",
                                backgroundColor: selectedCourses.length === 0 ? "#444" : "green", // Subtle dark gray for a timeless appearance
                                color: "white", // White text for contrast
                                border: "1px solid #333", // Soft border for structure
                                borderRadius: "5px", // Slightly rounded corners
                                fontSize: "16px", // Balanced font size
                                fontWeight: "bold", // Bold text for emphasis
                                fontFamily: "Georgia, serif", // A classic font choice
                                cursor: "pointer", // Keep the pointer style for interactivity
                                textAlign: "center"
                            }}
                            disabled={selectedCourses.length === 0}
                        >
                            CheckOut
                        </Button>
                    </div>
                </section>
                <div
                    className="headerBackground"
                    style={{
                        height: "80%",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "15px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    {packages.filter(data => data.packageId > packageId).map((course) => (
                        <div
                            key={course.courseId}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "15px",
                                padding: "15px",
                                background: "white",
                                borderRadius: "10px",
                                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)"
                            }}
                        >
                            {/* Product Image */}
                            <img
                                src={courseImage.find((data) => data.courseId === course.courseId)?.posterLink}
                                alt={course.courseName}
                                style={{
                                    width: "100px",
                                    height: "auto",
                                    borderRadius: "8px"
                                }}
                            />

                            {/* Product Details */}
                            <div style={{ flex: 1, marginLeft: "15px" }}>
                                <h3 style={{ margin: "0 0 5px 0", color: "#333" }}>{course.courseName}</h3>
                                <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>{course.courseDescription}</p>
                                <p style={{ margin: "5px 0", fontWeight: "bold", color: "#333" }}>Price: ₹{course.coursePrice}</p>
                            </div>

                            {/* Add to Cart Button */}
                            {selectedCourses.find(data => data.courseId === course.courseId) ? (
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        backgroundColor: "#d9534f",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        fontSize: "14px"
                                    }}
                                    onClick={() => handleRemoveFromCart(course)}
                                >
                                    Remove from Cart
                                </Button>
                            ) : (
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        backgroundColor: "#5cb85c",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        fontSize: "14px"
                                    }}
                                    onClick={() => handleAddToCart(course)}
                                >
                                    Add to Cart
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
            </section>
        </>
    </>
};

export default CourseOrders;