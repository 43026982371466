import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { getAdminPannel } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';

const QualityUserInformation = () => {
    const [qualityUserType, setQualityUserType] = useState(6);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    // Handle page change
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const qualityType = [
        {
            "typeId": 6,
            "typeName": "Un-Paid Balance"
        },
        {
            "typeId": 7,
            "typeName": "One Day Income"
        },
        {
            "typeId": 8,
            "typeName": "Sevent Day Income"
        },
        {
            "typeId": 9,
            "typeName": "Current Seven Day Income"
        },
        {
            "typeId": 10,
            "typeName": "Monthly Income"
        }
    ]

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getUserInformation = async (pannelId: number) => {
        setLoader(true);
        var response = await getAdminPannel(pannelId);
        if (response.statusCode === 200) {
            debugger
            const userDetails = pannelId === 6 ? response.data.adminPannel.unpaidBalance :
                pannelId === 7 ? response.data.adminPannel.oneDayIncome :
                    pannelId === 8 ? response.data.adminPannel.sevenDayIncome :
                        pannelId === 9 ? response.data.adminPannel.currentSevenDayIncome :
                            pannelId === 10 ? response.data.adminPannel.monthlyIncome : "";
            setUserInformation(userDetails);
            setFilterData(userDetails);
            setQualityUserType(pannelId);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getUserInformation(6);
            runValue.current = true;
        }
    }, [runValue]);
    const [userInformation, setUserInformation] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "balance": 0,
        }
    ]);
    const [filterData, setFilterData] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "balance": 0,
        }
    ]);
    // Slice data for current page
    const paginatedData = filterData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string, dateOnly: boolean = false) => {
        const date = new Date(isoString);

        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();

        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
        var formattedDateTime = "";
        if (dateOnly) {
            formattedDateTime = `${day} ${monthName} ${year}`;
        }
        else {
            formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
        }
        // Combine date and time

        return <div>{formattedDateTime}</div>;
    };
    const search = (event: any) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = userInformation.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
            data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setFilterData(filtered);
        setPage(0);
    }
    return (
        <>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <section className='headerBackground'>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "15px", // Space between buttons
                            padding: "20px",
                            flexWrap: "wrap", // Ensures buttons wrap on smaller screens
                            backgroundColor: "#343434", // Light background for contrast
                            border: "1px solid #ddd", // Subtle border for structured appearance
                            borderRadius: "10px", // Rounded edges
                        }}>
                            {
                                qualityType.map((data, index) => (
                                    <Button
                                        key={index} // Add a unique key for each button
                                        style={{
                                            backgroundColor: "#0056b3", // Classic blue
                                            color: "white",
                                            borderRadius: "8px", // Rounded edges
                                            padding: "12px 24px", // Generous padding
                                            boxShadow: "inset 0px 0px 3px rgba(255, 255, 255, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.2)", // Classic shadow
                                            fontWeight: "bold", // Bold text
                                            border: "1px solid #004494", // Subtle border for button definition
                                            width: "100%", // Ensures buttons are full-width on small screens
                                            maxWidth: "250px", // Limits button size on large screens
                                        }}
                                        onClick={() => { getUserInformation(data.typeId) }}
                                    >
                                        {data.typeName}
                                    </Button>
                                ))
                            }
                        </div>
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Growniverse Users</h3>
                                <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Input placeholder='Search' onChange={search} />
                                </div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{ fontWeight: "bold" }}>Sr.No.</TableCell>
                                            <TableCell align="center" style={{ fontWeight: "bold" }}>GrowniverseId</TableCell>
                                            <TableCell align="center" style={{ fontWeight: "bold" }}>Full Name</TableCell>
                                            <TableCell align="center" style={{ fontWeight: "bold" }}>{qualityType.find(data=>data.typeId === qualityUserType)?.typeName}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {paginatedData.map((statement, index) => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="center">{statement.growniverseId}</TableCell>
                                                <TableCell align="center">{statement.fullName}</TableCell>
                                                <TableCell align="center">{statement.balance}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20]}
                                    component="div"
                                    count={userInformation.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    </section>
                )
            }
        </>
    );
};

export default QualityUserInformation;
