import "../assets/css/package.css"
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import "../components/WelcomeSection.css"
import { useEffect, useRef, useState } from "react";
import { putUserLogin } from "../handler/LoginAndSingupHandler";
const Hold = () => {
    const [fullName, setFullName] = useState("");
    const [growniverseId, setGrowniverseId] = useState("");
    const hasRun = useRef(false);
    const userLoginOnRefresh = async () => {
        var response = await putUserLogin({
          "growniverseId": "",
          "password": ""
        });
        if (response.statusCode === 200) {
          var userData = response.data.loginResponse;
          const pathName = window.location.pathname;
          if (pathName !== "/package" && pathName !== "/hold" && pathName !== "/packageDetail" && pathName !== "/payment") {
            if (userData.profile.status === 2) {
              window.location.href = "/package"
            }
            else if (userData.profile.status === 3) {
              window.location.href = "/hold"
            }
          }
          else if (pathName === "/payment") {
            if (userData.profile.status >= 4) {
              window.location.href = "/dashboard"
            }
          }
          else if (pathName === "/hold") {
            if (userData.profile.status < 3) {
              window.location.href = "/package"
            }
          }
          localStorage.setItem("appkey", userData.appKey)
          localStorage.setItem("userProfile", JSON.stringify(userData.profile));
          localStorage.setItem("account", JSON.stringify(userData.account));
          if (userData.profile.packageId === 0) {
            localStorage.setItem("packages", JSON.stringify(userData.packages));
          }
          if (userData.profile.isAdmin) {
            window.location.href = "/packagepayment";
          }
        }
      }
    useEffect(() => {
        if (!hasRun.current) {
            const appKey = localStorage.getItem("appkey");
            if (appKey === null) {
                window.location.href = "/";
            }
            else {
                const storedProfile = localStorage.getItem("userProfile");
                const userProfile = JSON.parse(storedProfile ?? "");
                if (userProfile.status > 3) {
                    window.location.href = "/dashboard";
                }
                setFullName(userProfile.fullName);
                setGrowniverseId(userProfile.growniverseId);
            }
            hasRun.current = true;
        }
    }, [hasRun])
    return <>
        <section style={{ background: "#343434", height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="headerBackground" style={{
                height: "500px", width: "80%", borderRadius: "30px",
                display: "flex", justifyContent: "center", alignItems: "center"
            }}>
                <p style={{ textAlign: "center", fontSize: "20px", font: "Sans-serif", color: "white" }}>
                    Hi, <b>{fullName}</b><br />
                    <b>{growniverseId}</b><br/><br/>
                    We are excited to Welcome you in our Universe,<br />
                    Your package activation is currently in progress.<br />
                    Please note that it may take up to 6 hours for Approval.<br />
                    We will notify you via Mail as soon as the activation is complete.<br />
                    We Appreciate your Patience<br />
                    Best regards...<br /><br />
                    <img src={GrowniverseLogo} width={"30%"} alt="Growniverse" />
                </p>
            </div>
        </section>
    </>
};

export default Hold;