import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUserNameByGId, resetPassword } from '../handler/LoginAndSingupHandler';
import axios from 'axios';
import { putUserSignOpt } from '../handler/LoginAndSingupHandler';
import { putUserLogin } from '../handler/LoginAndSingupHandler';
import { Alert, Button } from '@mui/material';
import UserLoader from '../Dashboard/UserLoader';

type LoginAndSignupPopupProps = {
  onClose: () => void;
};
const LoginAndSignupPopup: React.FC<LoginAndSignupPopupProps> = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState('login');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [loginUserName, setLoginUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [loginNameError, setloginNameError] = useState("");
  const [loginUserValid, setLoginUserValid] = useState(false);
  const [formData, setFormData] = useState({});
  const [invalidPassword, setInvalidPassword] = useState(false);
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
  const [isValid, setIsValid] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [password, setPassowrd] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [states, setStates] = useState<State[]>([]);
  const [loadingStates] = useState(false);
  const [singUpData, setSingUpData] = useState({});
  const [loader, setLoader] = useState(false);
  const [forgetEmailId, setForgetEmailId] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const checkValidPassword = (event: any) => {
    const inputPassword = event.target.value;
    setPassowrd(event.target.value)
    setIsValid(passwordRegex.test(inputPassword)); // Validate password
    if (confirmPassword === inputPassword) {
      setIsMatch(true);
    }
    else {
      setIsMatch(false);
    }
    handleSignUpChange(event)
  };
  const checkConfirmPassword = (event: any) => {
    const inputPassword = event.target.value;
    setConfirmPassowrd(inputPassword);
    if (password === inputPassword) {
      setIsMatch(true);
    }
    else {
      setIsMatch(false);
    }
  };
  type State = {
    name: string;
    state_code: string;
  };

  const getUserNameByGID = async (event: any) => {
    var growniverseid = event.target.value;
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setLoginUserName(response.data.fullName);
        setloginNameError("");
        setLoginUserValid(true);
      }
      else {
        setloginNameError(response.message);
        setLoginUserName("");
        setLoginUserValid(false);
      }
    }
  }
  const [resetUserName, setResetUserName] = useState("");
  const [resetUserError, setResetUserError] = useState("");
  const [resetUserValid, setResetUserValid] = useState(false);
  const getUserNameByEmail = async (event: any) => {
    var growniverseEmail = event.target.value;
    if (growniverseEmail.length > 0) {
      var response = await getUserNameByGId(growniverseEmail);
      if (response.success) {
        setResetUserName(response.data.fullName);
        setResetUserError("");
        setResetUserValid(true);
      }
      else {
        setResetUserError(response.message);
        setResetUserName("");
        setResetUserValid(false);
      }
    }
  }

  const getUserNameBySGID = async (event: any) => {
    var growniverseid = event.target.value;
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setUserName(response.data.fullName);
        setUserNameError("");

      }
      else {
        setUserNameError("Invalid Sponsor GrowniverseId");
        setUserName("");

      }
    }
  }
  const [isEmailValid, setIsEmailValid] = useState(false);
  const getCheckEmail = async (event: any) => {

    var growniverseid = event.target.value;
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setIsEmailValid(true);
      }
      else {
        setIsEmailValid(false);
      }
    }
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target; // Get name and value from the input
    if (name === "Password") {
      setInvalidPassword(false);
    }
    setFormData((prev) => ({ ...prev, [name]: value })); // Update state dynamically
  };

  const [sponsorGrowniverseId,setSponsorGrowniverseId] = useState("");

  const handleSignUpChange = (e: any) => {
    const { name, value } = e.target; // Get name and value from the input
    if (name === "Password") {
      setInvalidPassword(false);
    }
    if (name === "sponsorGrowniverseId") {
      setSponsorGrowniverseId(value);
    }
    setSingUpData((prev) => ({ ...prev, [name]: value })); // Update state dynamically
  };

  const fetchCountries = async () => {
    try {

      const response = await axios.get("https://countriesnow.space/api/v0.1/countries/states");
      const selectedCountryData = response.data.data.find((c: any) => c.name === "India");

      if (selectedCountryData) {
        setStates(selectedCountryData.states);
      } else {
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const loginClick = async () => {
    localStorage.clear();
    setLoader(true);
    var response = await putUserLogin(formData);
    
    if (response.statusCode === 200) {
      var userData = response.data.loginResponse;
      localStorage.setItem("appkey", userData.appKey)
      localStorage.setItem("userProfile", JSON.stringify(userData.profile));
      localStorage.setItem("account", JSON.stringify(userData.account));
      localStorage.setItem("HeighestEarnerWeek", JSON.stringify(userData.heighestEarnerOfWeekDtos));
      if (userData.profile.isAdmin) {
        window.location.href = "/packagepayment";
      }
      else {
        if (userData.profile.packageId === 0) {
          localStorage.setItem("packages", JSON.stringify(userData.packages));
          if (userData.profile.status === 2) {
            window.location.href = "/package"
          }
          else if (userData.profile.status === 3) {
            window.location.href = "/hold"
          }
        }
        else {
          window.location.href = "/dashboard";
        }
      }
    }
    else if (response.statusCode === 401) {
      setLoader(false);
      setInvalidPassword(true);
    }
    else if (response.statusCode === 405) {
      localStorage.setItem("appkey", "NeedVerification")
      localStorage.setItem("data", JSON.stringify(response));
      window.location.href = "/emailConfirmation";
    }
  }
  const [resetRequestSend, setResetRequestSend] = useState(false);
  const forgetPassword = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    await resetPassword({
      "emailId": forgetEmailId,
      "resetChange": 1,
      "newPasswordToken": "",
      "newPassword": ""
    });
    setResetRequestSend(true);
    setLoader(false);
  }
  const [captchaValue, setCaptchaValue] = useState(null);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value); // Captcha token
  };
  const userSingOpt = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    localStorage.clear();
    if (userName.length > 0 && !isEmailValid) {
      var response = await putUserSignOpt(singUpData);
      localStorage.setItem("appkey", response.data.userId);
      if (response.statusCode === 201) {
        window.location.href = "/emailconfirmation"
      }
      else {
        setLoader(false);
        alert("An unexpected error occurred. Please try again.")
        window.location.reload();
      }
    }
    else {
      alert("Mail-ID already exists. Please choose a different Mail");
      setLoader(false);
    }
  }
  const handleChangeForgetInput = (event: any) => {
    setForgetEmailId(event.target.value);
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  const getUserNameByGIDEffelate = async (growniverseid:string) => {
    if (growniverseid.length > 0) {
      var response = await getUserNameByGId(growniverseid);
      if (response.success) {
        setUserName(response.data.fullName);
        setUserNameError("");

      }
      else {
        setUserNameError("Invalid Sponsor GrowniverseId");
        setUserName("");

      }
    }
  }
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const runOnce = useRef(false);
  useEffect(() => {
    if (runOnce.current === false) {
      const sponserGID = query.get("affiliatelink");
      if (sponserGID) {
        setActiveTab("register")
        setSponsorGrowniverseId(sponserGID ?? "");
        getUserNameByGIDEffelate(sponserGID ?? "");
        setSingUpData((prev) => ({ ...prev, ["sponsorGrowniverseId"]: sponserGID }));
      }
      runOnce.current = true;
    }
  }, [runOnce])
  return (
    <>
      <div className="modal login fade show" id="loginModal" role="dialog" aria-labelledby="loginModalLabel" aria-modal="true" style={{ display: 'block', paddingLeft: 0 }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {
              loader && (
                <div>
                  <UserLoader />
                </div>
              )
            }
            {!loader && (
              <>
                <div className="modal-header border-0">
                  {
                    activeTab === "forgetpassword" ?
                      <h5 className="modal-title" id="loginModalLabel">Forget Password</h5>
                      :
                      <h5 className="modal-title" id="loginModalLabel">Log In &amp; Register</h5>
                  }
                  <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {
                    activeTab !== "forgetpassword" &&
                    (
                      <ul className="nav nav-tabs nav-tabs-02 justify-content-center" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${activeTab === 'login' ? 'active' : ''}`}
                            id="login-tab"
                            onClick={() => setActiveTab('login')}
                            role="tab"
                            aria-controls="login"
                            aria-selected={activeTab === 'login'}
                            href="#login"
                          >
                            <span>Log In</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${activeTab === 'register' ? 'active' : ''}`}
                            id="register-tab"
                            onClick={() => setActiveTab('register')}
                            role="tab"
                            aria-controls="register"
                            aria-selected={activeTab === 'register'}
                            href="#register"
                          >
                            <span>Register</span>
                          </a>
                        </li>
                      </ul>
                    )
                  }
                  <div className="tab-content" id="myTabContent">
                    {activeTab === 'login' && (
                      <div className="tab-pane fade active show" id="login" role="tabpanel" aria-labelledby="login-tab">
                        <form onSubmit={loginClick}>
                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Growniverse ID <span style={{ color: "red" }}>*</span></label>
                              <input type="text" name='GrowniverseId' onChange={handleInputChange} className='form-control' onBlur={getUserNameByGID} disabled={loginUserName.length > 0} required />
                              <span style={{ color: 'green', alignItems: 'center' }}>{loginUserName}</span>
                              <h6 style={{ color: 'red', alignItems: 'center' }}>
                                {loginNameError.length > 0 && (
                                  <Alert severity="error" variant="outlined">
                                    Please Enter Valid Login Growniverse ID
                                  </Alert>
                                )}
                              </h6>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Passoword</label>
                              <input type="password" name='Password' className="form-control" onChange={handleInputChange} disabled={!loginUserValid} required />
                              <h6 style={{ color: 'red', alignItems: 'center' }}>
                                {invalidPassword && (
                                  <Alert severity="error" variant="outlined">
                                    Invalid Password
                                  </Alert>
                                )}
                              </h6>
                            </div>
                          </div>
                          <div className='row' style={{ gap: "10px" }}>
                            <div className='col text-center'>
                              <button type="submit" className="btn btn-primary">Sign In</button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <Button onClick={() => setActiveTab('forgetpassword')}>Forget Password</Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {activeTab === 'register' && (
                      <div className="tab-pane fade active show" id="register" role="tabpanel" aria-labelledby="register-tab">
                        <form onSubmit={userSingOpt}>
                          <div style={{ padding: "10px" }}>
                            <Alert severity="info" variant="outlined">
                              Your Details Must Be Match With Your Aadhar Card Details.
                            </Alert>
                          </div>
                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Sponsor Growniverse ID <span style={{ color: "red" }}>*</span></label>
                              <input name='sponsorGrowniverseId' value={sponsorGrowniverseId} type="text" className='form-control' onChange={handleSignUpChange} onBlur={getUserNameBySGID} disabled={userName.length > 0} required />
                              <span style={{ color: 'green', alignItems: 'center' }}>{userName}</span>
                              <h6 style={{ color: 'red', alignItems: 'center' }}>
                                {userNameError.length > 0 && (
                                  <Alert severity="error" variant="outlined">
                                    Please Enter Valid Sponsor Growniverse ID
                                  </Alert>
                                )}
                              </h6>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Full Name <span style={{ color: "red" }}>*</span></label>
                              <input name='fullName' type="text" className='form-control' placeholder="Full Name" required onChange={handleSignUpChange} />
                            </div>
                            <div className='col mb-3'>
                              <label>Phone Number <span style={{ color: "red" }}>*</span></label>
                              <input name='phoneNumber' type="text" className='form-control' placeholder="Phone Number" required onChange={handleSignUpChange} />
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col mb-3">
                              <label>Email Address <span style={{ color: "red" }}>*</span></label>
                              <input name='email' type="email" className='form-control' placeholder="Email Address" onBlur={getCheckEmail} required onChange={handleSignUpChange} />
                              {
                                isEmailValid && (
                                  <Alert severity="error" variant="outlined">
                                    Mail-ID already exists. Please choose a different Mail
                                  </Alert>
                                )
                              }
                            </div>
                            <div className='col mb-3'>
                              <label>Date Of Birth <span style={{ color: "red" }}>*</span></label>
                              <input name='dateOfBirth' type="date" placeholder='Date Of Birth' className='form-control' max={new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().split("T")[0]} required onChange={handleSignUpChange} />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Select Country</label>
                              <select className='form-control' disabled>
                                <option value="India" selected>India</option>
                              </select>
                            </div>
                            <div className='col mb-3'>
                              <label>Select State <span style={{ color: "red" }}>*</span></label>
                              {loadingStates ? (
                                <p>Loading states...</p>
                              ) : (
                                <select name='state' disabled={!states.length} className='form-control' onChange={handleSignUpChange} required>
                                  <option value="">-- Select State --</option>
                                  {states.map((state, index) => (
                                    <option key={index} value={state.state_code}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Address <span style={{ color: "red" }}>*</span></label>
                              <input name='address' type="text" className='form-control' placeholder="Address" onChange={handleSignUpChange} required />
                            </div>
                            <div className='col mb-3'>
                              <label>Pincode <span style={{ color: "red" }}>*</span></label>
                              <input name='pincode' type="number" className='form-control' placeholder="Pincode" onChange={handleSignUpChange} required />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col mb-3'>
                              <label>Password <span style={{ color: "red" }}>*</span></label>
                              <div className="input-wrapper position-relative password-field">
                                <input
                                  type={passwordVisible ? "text" : "password"}
                                  placeholder="Password"
                                  className='form-control'
                                  onChange={checkValidPassword}
                                  name='password'
                                  required />
                                <button className='eye-btn' type="button" onClick={togglePasswordVisibility}>
                                  <i className="eye-icon">👁️</i>
                                </button>
                              </div>
                            </div>
                            <div className='col mb-3'>
                              <label>Confirm Password <span style={{ color: "red" }}>*</span></label>
                              <div className="input-wrapper position-relative password-field">
                                <input
                                  type={confirmPasswordVisible ? "text" : "password"}
                                  placeholder="Confirm Password" className='form-control'
                                  disabled={!isValid}
                                  onChange={checkConfirmPassword}
                                  required />
                                <button
                                  type="button"
                                  className='eye-btn'
                                  onClick={toggleConfirmPasswordVisibility}
                                >
                                  <i className="eye-icon">👁️</i>
                                </button>
                              </div>
                            </div>
                            {password.length > 0 && (
                              isValid ? (
                                <p style={{ color: "green" }}>Password is Valid.</p>
                              ) : (
                                <p style={{ color: "red" }}>Password must include uppercase, lowercase, number, special character, no spaces, and be 8-16 characters.</p>
                              )
                            )}
                            {confirmPassword.length > 0 && (
                              isMatch ? (
                                <p style={{ color: "green" }}>Confirm Password Is Valid.</p>
                              ) : (
                                <p style={{ color: "red" }}>Password Confirm Password.</p>
                              )
                            )}
                          </div>
                          <div className="form-group">
                            <div className="form-check mb-3">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                              <label className="form-check-label ps-1" htmlFor="flexCheckDefault">
                                I agree to the <Link to={'/privacypolicy'} target='_blank'>Privacy Policy </Link>and{" "}
                                <Link to={'/termsconditions'} target='_blank'>Terms & Conditions</Link>.
                              </label>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col mb-1 text-center'>
                              <button type="submit" className="btn btn-primary"  >
                                Register Account
                              </button>
                            </div>
                          </div>
                          <div className="text-center">
                            Already have an account? <Link to="#" onClick={() => setActiveTab('login')}>Sign In</Link>
                          </div>
                        </form>
                      </div>
                    )}
                    {
                      activeTab === "forgetpassword" && (
                        resetRequestSend ?
                          <>
                            <Alert severity="success" style={{ fontSize: "15px", margin: "20px" }}>
                              Your Request has been Submitted Successfully. To Reset Your Password, Please Follow the Instructions Provided in the Mail sent to your Registered Mail Address.
                            </Alert>
                          </>
                          :
                          <>
                            <form onSubmit={forgetPassword}>
                              <div className='row'>
                                <div className='col mb-3'>
                                  <label>Mail Id <span style={{ color: "red" }}>*</span></label>
                                  <input type="email" name='Mail Id' className='form-control' onChange={handleChangeForgetInput} onBlur={getUserNameByEmail} disabled={resetUserName.length > 0} required />
                                  <span style={{ color: 'green', alignItems: 'center' }}>{resetUserName}</span>
                                </div>
                                <h6 style={{ color: 'red', alignItems: 'center' }}>
                                  {(resetUserError.length > 0 && !resetUserValid) &&
                                    <Alert severity="error" variant="outlined">
                                      Mail-Id Not Found: Please check the Mail Address and try again.
                                    </Alert>
                                  }
                                </h6>
                              </div>
                              <div className='row'>
                                <div className='col text-center'>
                                  <button type="submit" className="btn btn-primary" disabled={!resetUserValid}>Reset Password</button>
                                </div>
                              </div>
                            </form>
                          </>
                      )
                    }
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAndSignupPopup;
