import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonials.css';
import KaranRajput from "../Photos/Testimonials/Students/KaranRajput.png";
import BhushanSharma from "../Photos/Testimonials/Students/BhushanSharma.png";
import JiyaSharma from "../Photos/Testimonials/Students/JiyaSharma.jpg";
import Kajal from "../Photos/Testimonials/Students/Kajal.jpg";
import SoniaChoudhary from "../Photos/Testimonials/Students/SoniaChoudhary.jpg";
import ReshmaDevi from "../Photos/Testimonials/Parents/ReshmaDevi.jpg";
import MrAndMrsSharma from "../Photos/Testimonials/Parents/MrAndMrsSharma.jpg";
import ReenaDevi from "../Photos/Testimonials/Parents/ReenaDevi.png";
import ShardaDevi from "../Photos/Testimonials/Parents/ShardaDevi.png";


const Testimonials = () => {
    const [active, setActive] = useState('Students');
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const typeOfTestimonial = [
        'Students',
        'Parents',
    ];
    const testimonials = [
        {
            id:1,
            name: 'Mr. and Mrs. Sharma',
            img: MrAndMrsSharma,
            testimonial: "We've Seen Significant Improvement in Our Both Children's Confidence, Communication And essential life skills. Highly Recommend to other Parents",
            type: "Parents"
        },
        {
            id:2,
            name: 'Mrs. Sharda Devi',
            img: ShardaDevi,
            testimonial: "The Mentors at Growniverse Provides The Best Environment Possible For Our Both Children's Progress.",
            type: "Parents"
        },
        {
            id:3,
            name: 'Mrs. Reena Devi',
            img: ReenaDevi,
            testimonial: "Growniverse has an Excellent Communication System, Which Ensure that Parents are always aware of their Child shedule and performance",
            type: "Parents"
        },
        {
            id:4,
            name: 'Mrs Reshma Devi ',
            img: ReshmaDevi,
            testimonial: "Growniverse has Exceeded Our Expectations in every way. The Quality Education and Support has made a Significant Different in My Daughter's Life",
            type: "Parents"
        },
        {
            id:5,
            name: 'Karan Rajput',
            img: KaranRajput,
            testimonial: "Growniverse is where Minds are opened and Future are Shaped.",
            type: "Students"
        },
        {
            id:6,
            name: 'Sonia Choudhary',
            img: SoniaChoudhary,
            testimonial: "I am Greatful for the Skills, Knowledge and Confidence Growniverse Provide to me.",
            type: "Students"
        },
        {
            id:7,
            name: 'Bhushan Sharma',
            img: BhushanSharma,
            testimonial: "You are More than a Digital Learning Platform,Your Environment is a Blessing in My Life.",
            type: "Students"
        },
        {
            id:8,
            name: 'Kajal',
            img: Kajal,
            testimonial: "I am truly grateful and honored to be a part of the incredible Growniverse.",
            type: "Students"
        },
        {
            id:9,
            name: 'Jiya Sharma',
            img: JiyaSharma,
            testimonial: "I improve my Communication Skills very much from Growniverse.",
            type: "Students"
        }
    ];
    const handleFilter = (type: any) => {
        setActive(type);
    };
    return (
        <div className='space-ptb bg-primary testimonial-section mb-4'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-6 '>
                        <h2 className='mb-4 text-center text-white'>What People Feel About Us</h2>
                        <p className='text-white text-center' >Your Reviews are Heighly Valued for Us and We Thankyou for Taking the Time to Share Your Thoughts.</p>
                    </div>
                </div>
                <div className="filters-group mb-2 mb-4">
                    {typeOfTestimonial.map((type) => (
                        <button
                            key={type}
                            className={`btn-filter ${active === type ? 'active' : ''}`}
                            onClick={() => handleFilter(type)}
                            style={{ color: "white" }}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </button>
                    ))}
                </div>
                <Slider {...settings}>
                    {testimonials
                        .filter(
                            (testimoni) =>
                                testimoni.type === active
                        )
                        .map((course) => (
                            <div className='slide-item px-3 pb-80' key={course.id}>
                                <div className="card" style={{height:"250px"}}>
                                    <div className="card-body">
                                        <img className='testimoni-user' src={course.img} alt='Growniverse'/>
                                        <p className="card-text">{course.testimonial}</p>
                                        <h5 className="card-title">{course.name}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </div>

    );
};

export default Testimonials;
