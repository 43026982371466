import React, { useEffect, useRef } from 'react';
import { Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { getAdminPannel } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';

const UserInformation = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    // Handle page change
    const handleChangePage = (event:any,newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getUserInformation = async () => {
        setLoader(true);
        var response = await getAdminPannel(4);
        if (response.statusCode === 200) {
            const userDetails = response.data.adminPannel.userDetails;
            setUserInformation(userDetails);
            setFilterData(userDetails);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getUserInformation();
            runValue.current = true;
        }
    }, [runValue]);
    const [userInformation, setUserInformation] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "emailId": "string",
            "phoneNumber": "string",
            "status": 1,
            "registrationDate": "2025-01-07T06:23:53.095Z",
            "dateOfBirth": "2025-01-07"
        }
    ]);
    const [filterData, setFilterData] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "emailId": "string",
            "phoneNumber": "string",
            "status": 1,
            "registrationDate": "2025-01-07T06:23:53.095Z",
            "dateOfBirth": "2025-01-07"
        }
    ]);
    // Slice data for current page
    const paginatedData = filterData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string, dateOnly: boolean = false) => {
        const date = new Date(isoString);

        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();

        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
        var formattedDateTime = "";
        if (dateOnly) {
            formattedDateTime = `${day} ${monthName} ${year}`;
        }
        else {
            formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
        }
        // Combine date and time

        return <div>{formattedDateTime}</div>;
    };
    const search = (event:any) =>{
        const searchTerm = event.target.value.toLowerCase();
        const filtered = userInformation.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
        data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setFilterData(filtered);
        setPage(0); // Reset to first page
    }
    return (
        <>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <section className='headerBackground'>
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Growniverse Users</h3>
                                <div style={{padding:"10px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <Input placeholder='Search' onChange={search}/>
                                </div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>UserInformationId</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>GrowniverseId</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Full Name</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Status</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Email-Id</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Phone-Number</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Date Of Birth</TableCell>
                                            <TableCell align="center" style={{fontWeight:"bold"}}>Registation Date & Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {paginatedData.map(statement => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {statement.userInformationId}
                                                </TableCell>
                                                <TableCell align="center">{statement.growniverseId}</TableCell>
                                                <TableCell align="center">{statement.fullName}</TableCell>
                                                <TableCell align="center">{statement.status === 0 ? "Email Not Confirmed" : statement.status === 1 ? "Email Confirmed" : statement.status === 2 ? "No Package" : statement.status === 3 ? "In Progress" : statement.status === 4 ? "KYC Pending" : statement.status === 5 ? "KYC Requested" : statement.status === 6 ? "Active" : "InActive"}</TableCell>
                                                <TableCell align="center">{statement.emailId}</TableCell>
                                                <TableCell align="center">{statement.phoneNumber}</TableCell>
                                                <TableCell align="center">{DateTimeDisplay(statement.dateOfBirth, true)}</TableCell>
                                                <TableCell align="center">{DateTimeDisplay(statement.registrationDate)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20]}
                                    component="div"
                                    count={userInformation.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    </section>
                )
            }
        </>
    );
};

export default UserInformation;
