import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { getAdminPannel, kycRequestAction } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';

const KycDetail = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event:any,newPage: any) => {
        
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const [loader, setLoader] = useState(false);
    const handelKycRequestAction = async (kycId: number, accept: number) => {
        setLoader(true);
        await kycRequestAction({
            "kycId": kycId,
            "status": accept
        });
        window.location.reload();
    }
    var runValue = useRef(false);
    type kycDetail = {
        userInformationId: number,
        growniverseId: string,
        fullName: string,
        status: number,
        bankName: string,
        ifscCode: string,
        bankImage: string,
        accountName: string,
        accountNumber: string,
        upiId: string,
        upiName: string,
        adharCard: string,
        adharCardImage: string,
        adharCardImageBack: string,
        panCard: string,
        panCardImage: string,
        kycId: number,
    };

    const kycDetails = [
        {
            "growniverseId": "",
            "fullName": "",
            "kycId": 0,
            "bankName": "",
            "accountName": "",
            "accountNumber": "",
            "ifscCode": "",
            "bankImage": "",
            "upiId": "",
            "upiName": "",
            "adharCard": "",
            "adharCardImage": "",
            "adharCardImageBack": "",
            "panCard": "",
            "panCardImage": "",
            "status": 1,
            "userInformationId": 0
        }
    ];
    const [pendingRequest, setPendingRequest] = useState(kycDetails);
    const [succesfullRequest, setSuccesfullRequest] = useState(kycDetails);
    const paginatedData = succesfullRequest.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    useEffect(() => {
        if (runValue.current === false) {
            getKycDetail();
            runValue.current = true;
        }
    }, [runValue]);
    const filterTasksByStatus = (kycDetails: any, status: number) => {
        return kycDetails.filter((task: kycDetail) => task.status === status);
    };
    const filterTasksByNotStatus = (kycDetails: any, status: number) => {
        return kycDetails.filter((task: kycDetail) => task.status !== status).sort((a: kycDetail, b: kycDetail) => a.kycId > b.kycId ? -1 : 1);
    };
    const [kycUserDetail,setKycUserDetail] = useState(kycDetails);
    const getKycDetail = async () => {
        setLoader(true);
        var response = await getAdminPannel(2);
        if (response.statusCode === 200) {
            const kycDetails = response.data.adminPannel.kycDetails;
            setPendingRequest(filterTasksByStatus(kycDetails, 1));
            setSuccesfullRequest(filterTasksByNotStatus(kycDetails, 1));
            setKycUserDetail(kycDetails);
        }
        setLoader(false);
    }
    const search = (event:any) =>{
        
        const searchTerm = event.target.value.toLowerCase();
        const filtered = kycUserDetail.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
            data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setSuccesfullRequest(filterTasksByNotStatus(filtered, 1))
        setPage(0); // Reset to first page
    }
    return (
        <>
            <section className='headerBackground'>
                {
                    loader && (
                        <UserLoader />
                    )
                }
                {
                    !loader && (
                        <>
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                <TableContainer style={{ background: "white" }}>
                                    <h3 style={{ textAlign: "center" }}>Kyc Request</h3>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Sr.No</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UserDetails</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Bank Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UPI Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Adhar Card Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Pan Card Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {pendingRequest.map((kyc, index) => (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.fullName}<br />
                                                        {kyc.growniverseId}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.bankName}<br />
                                                        {kyc.ifscCode}<br />
                                                        {kyc.accountName}<br />
                                                        {kyc.accountNumber}<br />
                                                        <img src={`data:image/jpeg;base64,${kyc.bankImage}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.upiId}<br />
                                                        {kyc.upiName}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.adharCard}<br />
                                                        <img src={`data:image/jpeg;base64,${kyc.adharCardImage}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} /><br />
                                                        <img src={`data:image/jpeg;base64,${kyc.adharCardImageBack}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.panCard}<br />
                                                        <img src={`data:image/jpeg;base64,${kyc.panCardImage}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ justifyContent: "space-between", gap: "10px" }}>
                                                        <Button style={{ border: "1px solid green", background: "green", color: "white" }} onClick={() => handelKycRequestAction(kyc.kycId, 1)}>Approve</Button><br /><br /><br />
                                                        <Button style={{ border: "1px solid red", background: "red", color: "white" }} onClick={() => handelKycRequestAction(kyc.kycId, 2)}>Reject</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20]}
                                        component="div"
                                        count={pendingRequest.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </div>
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                <TableContainer style={{ background: "white" }}>
                                    <h3 style={{ textAlign: "center" }}>Kyc Details</h3>
                                    <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Input placeholder='Search' onChange={search} />
                                    </div>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Sr.No</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UserDetails</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Bank Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UPI Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Adhar Card Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Pan Card Details</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {paginatedData.map((kyc, index) => (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.fullName}<br />
                                                        {kyc.growniverseId}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.bankName}<br />
                                                        {kyc.ifscCode}<br />
                                                        {kyc.accountName}<br />
                                                        {kyc.accountNumber}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.upiId}<br />
                                                        {kyc.upiName}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.adharCard}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {kyc.panCard}
                                                    </TableCell>
                                                    <TableCell align="center" style={kyc.status === 2 ? { color: "green", fontWeight: "bold" } : { color: "red", fontWeight: "bold" }}>
                                                        {kyc.status === 2 ? "Approved" : "Rejected"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20]}
                                        component="div"
                                        count={succesfullRequest.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};

export default KycDetail;
