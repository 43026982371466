import "../assets/css/package.css"
import scanner from "../Photos/Payment/Scanner.png"
import { Button, Input } from "@mui/material";
import "../components/WelcomeSection.css"
import { useEffect, useRef, useState } from "react";
import { paymentRequest, paymentRequestAction } from "../handler/accountHandler";
import growniverseImage from "../Photos/Logo/growniverse-appicon-transparent.svg";
import { MoonLoader } from 'react-spinners';
import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
interface InnerBannerProps {
    packageDetailData: {
        packageId: number,
        packageName: string,
        price: number
    };
    IsUpgrade: boolean
}
const Payment: React.FC<InnerBannerProps> = ({ packageDetailData, IsUpgrade }) => {
    const [file, setFile] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [unPaidBalance,setUnPaidBalance] = useState(0);
    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };
    const handleTrChange = (event: any) => {
        setTransactionId(event.target.value);
    };

    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const runOnce = useRef(false);
    const formData2 = new FormData();
    const packagePaymentTransaction = async () => {
        setLoader(true);
        formData2.append("userInformationId", userProfile.userInformationId.toString());
        formData2.append("packageId", packageDetailData.packageId.toString());
        formData2.append("packageName", packageDetailData.packageName.toString());
        formData2.append("payment", calculateGst(packageDetailData.price).toString());
        if (!transactionId) {
            alert('Please select a file before submitting.');
            setLoader(false);
            return;
        }
        formData2.append("transactionId", transactionId);
        if (!file) {
            alert('Please select a file before submitting.');
            setLoader(false);
            return;
        }
        formData2.append('imageData', file);
        if (IsUpgrade) {
            formData2.append("type", "2");
        }
        await paymentRequest(formData2);
        setLoader(false);
        if (IsUpgrade) {
            window.location.href = "/dashboard";
        }
        else {
            window.location.href = "/hold";
        }
    }
    const [insufficientFund,setInsufficientFund]=useState("");
    const PayByWallet = async()=>{
        setLoader(true);
        setInsufficientFund("");
        if (packageDetailData.price <= unPaidBalance)
        {
            var response = await paymentRequestAction({
                "packagePaymentId": 0,
                "paymentAccept": 1,
                "packagePaymentType": 2,
                "packageId": packageDetailData.packageId,
                "packageName": packageDetailData.packageName,
                "payment": calculateGst(packageDetailData.price)
              },userProfile.userInformationId)
            if(response.statusCode === 200)
            {
                window.location.href = "/dashboard"
            }
        }
        else{
            setInsufficientFund("Insufficient Wallet Funds. Kindly Request funds into your wallet to proceed with the transaction.")
        }
        setLoader(false);
    }

    const calculateGst = (price: number) => {
        return Math.round(Number(price) + price * 0.18)
    }
    const [paymentMethod, setPaymentMethod] = useState("");
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserProfile(userProfile);
            const storedAccount = localStorage.getItem("account");
            const userAccount = JSON.parse(storedAccount ?? "");
            setUnPaidBalance(userAccount.unPaidBalance);
            window.scrollTo(0, 0);
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        <section style={{ background: '#343434' }}>
            {
                loader && (
                    <>
                        <div
                            style={{
                                position: "absolute",
                                background: "white",
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                top: 0,
                                left: 0,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={growniverseImage}
                                alt="Loading..."
                                width="100px" // Adjust size as needed
                                style={{
                                    position: "absolute",
                                    animation: "spin 5s linear infinite", // Apply the spin animation
                                }}
                            />
                            <MoonLoader size="120" color="#f5b45f" />
                        </div>
                    </>
                )
            }
            {
                !loader && (
                    <>
                        <div style={{
                            background: "#fafafa",
                            padding: "40px",
                            border: "1px solid #ddd",
                            fontFamily: "'Times New Roman', serif",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)"
                        }}>
                            <TableContainer style={{
                                background: "#ffffff",
                                padding: "25px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)"
                            }}>
                                <h3 style={{
                                    background: "#003b46",
                                    color: "#ffffff",
                                    textAlign: "center",
                                    padding: "15px",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    letterSpacing: "1px",
                                    textTransform: "uppercase"
                                }}>
                                    Billing Details
                                </h3>
                                <Table style={{ marginTop: "10px" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#333",
                                                borderBottom: "1px solid #ddd"
                                            }}>Package Name</TableCell>
                                            <TableCell align="right" style={{
                                                fontSize: "16px",
                                                color: "#555",
                                                borderBottom: "1px solid #ddd",
                                                fontWeight:"bold"
                                            }}>{packageDetailData.packageName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left" style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#333",
                                                borderBottom: "1px solid #ddd"
                                            }}>Base Price</TableCell>
                                            <TableCell align="right" style={{
                                                fontWeight: "700",
                                                fontSize: "18px",
                                                color: "green",
                                                borderBottom: "1px solid #ddd"
                                            }}>₹{packageDetailData.price}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left" style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#333",
                                                borderBottom: "2px solid #ddd"
                                            }}>Total Amount (+18% Tax Included)</TableCell>
                                            <TableCell align="right" style={{
                                                fontWeight: "700",
                                                fontSize: "20px",
                                                color: "green",
                                                borderBottom: "2px solid #ddd"
                                            }}>₹{calculateGst(packageDetailData.price)}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                        <div>
                            <h4 style={{textAlign:"center",color:"white"}}>{paymentMethod === "" ? "Select" : "Selected"} Payment Method {paymentMethod}</h4>
                        </div>
                        <div style={{ padding: "20px", background: "white" }}>
                            <div className="row" style={{
                                background: "linear-gradient(to right,rgb(236, 186, 116), #fa9602)",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                border: paymentMethod === "WALLET" ? "4px solid black" : ""
                            }}>
                                {
                                    paymentMethod === "WALLET" &&
                                    <div className="col-1" style={{ display: "flex", justifyContent: "left", alignContent: "center", alignItems: "center" }}>
                                        <div style={{
                                            top: '50%',
                                            left: '0',
                                            transform: 'translateY(-50%)',
                                            height: '20px',
                                            width: '20px',
                                            borderRadius: '100%',
                                            border: '3px solid #3C898C',
                                            background: "#3C898C"
                                        }}>
                                        </div>
                                    </div>
                                }
                                <div
                                    className={paymentMethod === "WALLET" ? "header col-10" : "header"}
                                    style={{
                                        height: "500px",
                                        padding: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "15px",
                                        filter: paymentMethod === "WALLET" ? "" : "blur(2px)",
                                        opacity: paymentMethod === "WALLET" ? 1 : 0.8,
                                        backgroundColor: paymentMethod === "WALLET" ? "#fdf8ec" : "#f8f8f8",
                                        borderRadius: "15px",
                                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                                        transition: "all 0.3s ease",
                                        position: "relative", // Added for the badge
                                    }}
                                    onClick={() => setPaymentMethod("WALLET")}
                                >
                                    {/* Limited-Time Offer Badge */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            backgroundColor: "green",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            borderRadius: "10px",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                            fontSize:"15px"
                                        }}
                                    >
                                        🎉 Limited-Time Offer: Get 2% Cashback!
                                    </div>
                                    <h6 style={{ color: "#333", lineHeight: "1.6", textAlign: "center", marginBottom: "20px",fontSize:"20px",fontWeight:"bold" }}>
                                        Pay Using Your Wallet and Get Extra 2% Assured Cashback on Your Purchase.<br />
                                    </h6>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "20px",
                                            padding: "20px",
                                            background: "linear-gradient(to bottom, #f7f7f7, #eaeaea)", // Subtle gradient background
                                            borderRadius: "15px", // Smooth rounded edges
                                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for a clean, classic look
                                        }}
                                    >
                                        <h2
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "24px", // Adjusted font size for better balance
                                                color: "#333", // Neutral dark color for elegance
                                                margin: "0",
                                            }}
                                        >
                                            Wallet Balance
                                        </h2>
                                        <div
                                            style={{
                                                background: "white",
                                                width: "120px",
                                                height: "90px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontWeight: "bold",
                                                fontSize: "24px", // Slightly larger font for prominence
                                                color: "#fa9602",
                                                borderRadius: "15px",
                                                border: "2px solid #fa9602",
                                                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)", // Deeper shadow for a premium effect
                                                transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover transitions
                                            }}

                                        >
                                            ₹{unPaidBalance}
                                        </div>
                                    </div>
                                    <span style={{color:"red"}}>{insufficientFund}</span>
                                    <Button
                                        variant="contained"
                                        style={{
                                            background: paymentMethod === "WALLET" ? "green" : "#ccc",
                                            color: paymentMethod === "WALLET" ? "white" : "#666",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            padding: "10px 25px",
                                            borderRadius: "20px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            cursor: paymentMethod === "WALLET" ? "pointer" : "not-allowed",
                                            transition: "all 0.3s ease",
                                        }}
                                        disabled={paymentMethod !== "WALLET"}
                                        onClick={() => PayByWallet()}
                                    >
                                        Pay By WALLET <br />
                                        <span style={{ fontWeight: "bold" }}>+ Get Extra 2% Cashback</span>
                                    </Button>
                                </div>
                            </div><br />
                            <div className="row" style={{
                                background: "#343434",
                                borderColor: "white", 
                                borderStyle: "inset",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                border: paymentMethod === "UPI" ? "4px solid black" : "",
                            }}>
                                {
                                    paymentMethod === "UPI" &&
                                    <div className="col-1" style={{ display: "flex", justifyContent: "left", alignContent: "center", alignItems: "center" }}>
                                        <div style={{
                                            top: '50%',
                                            left: '0',
                                            transform: 'translateY(-50%)',
                                            height: '20px',
                                            width: '20px',
                                            borderRadius: '100%',
                                            border: '3px solid #3C898C',
                                            background:"#3C898C"
                                        }}>
                                        </div>
                                    </div>
                                }
                                <div className={paymentMethod === "UPI" ? "header col-10" : "header"} style={{
                                    background: "#343434", textAlign: "center", padding: "20px", marginBottom: "10px"
                                    , filter: paymentMethod === "UPI" ? "" : "blur(1px)",
                                    opacity: paymentMethod === "UPI" ? "" : 0.9,
                                }} onClick={() => { setPaymentMethod("UPI") }}>
                                    <img src={scanner} itemType="png" width={"300px"} height={"450px"} alt="Scanner Growniverse" />
                                    <h3 style={{ background: "#228B22", color: "#D3D3D3", marginTop: "10px" }}>
                                        UPI ID - growniverse@UPI
                                    </h3>
                                    <h2 style={{ background: "#899ba1" }}>
                                        Bank Name - PUNJAB NATIONAL BANK
                                    </h2>
                                    <h2 style={{ background: "#899ba1" }}>
                                        Account Number - 7974002100000530
                                    </h2>
                                    <h2 style={{ background: "#899ba1" }}>
                                        IFSC CODE - PUNB0797400
                                    </h2>
                                    <div className="header" style={{ height: "100px", background: "white", padding: "10px" }}>
                                        <Input name='imageFileData' type="file" onChange={handleFileChange} disabled={paymentMethod !== "UPI"} required />
                                        <h4> <Input name='transactionId' style={{ color: "black" }} placeholder="Enter Transaction Id" onChange={handleTrChange} disabled={paymentMethod !== "UPI"} required /></h4>
                                        <Button variant="contained" style={{ background: "#fa9602" }} disabled={paymentMethod !== "UPI"} onClick={packagePaymentTransaction}>
                                            Submit
                                        </Button>
                                    </div><br /><br />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </section>
    </>
};

export default Payment;