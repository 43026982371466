import axios from "axios";
const packageJson = require('../../package.json');
const createSignature = () => {
    // const crypto = require('crypto');
    // const secretKey = '5d928cd2-77d1-4415-abbb-4f225f40ee65';

    // // Format the payload string (equivalent of C# string.Format)
    // const payloadForSignature = `${payload}-` + secretKey;

    // // Create HMACSHA256 using crypto module
    // const hmac = crypto.createHmac('sha256', secretKey);
    // hmac.update(payloadForSignature);

    // // Compute the hash and convert it to base64
    // return hmac.digest('base64');
    const secretKey = "5d928cd2-77d1-4415-abbb-4f225f40ee65"
    return secretKey;
}
export const getUserNameByGId = async (GrowniverseId: string) => {

    const signature = createSignature();
    var response = await axios.get(packageJson.apiUrl + "Auth?GrowniverseId=" + GrowniverseId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const putUserSignOpt = async (userData: any) => {

    const signature = createSignature();
    const response = await axios.put(packageJson.apiUrl + "Auth", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature
        },
    });
    return response.data;
}

export const putUserLogin = async (userData: any) => {
    const signature = createSignature();
    const appKey = localStorage.getItem("appkey");
    const responseApi = await axios.post(packageJson.apiUrl + "Auth", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            "X-Growniverse-Appkey": appKey
        },
    });
    return responseApi.data;
}

export const activateEmailAsync = async (userData: any) => {
    const signature = createSignature();
    const responseApi = await axios.post(packageJson.apiUrl + "Auth/EmailConfirmation", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return responseApi.data;
}

export const userUpdate = async (userData: any) => {
    const signature = createSignature();
    const responseApi = await axios.post(packageJson.apiUrl + "Auth/UpdateProfile", userData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return responseApi.data;
}

export const GetUserReferral = async (GrowniverseId: string) => {

    const signature = createSignature();
    var response = await axios.get(packageJson.apiUrl + "Auth/UserReferral?GrowniverseId=" + GrowniverseId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const getAdminPannel = async (AdminDetail: number = 1) => {

    const signature = createSignature();
    var response = await axios.get(packageJson.apiUrl + "Auth/AdminPannel?AdminDetail=" + AdminDetail, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const passwordChange = async (userData: any) => {
    const signature = createSignature();
    const responseApi = await axios.post(packageJson.apiUrl + "Auth/PasswordChange", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return responseApi.data;
}

export const sendKycForApproval = async (userData: any) => {
    const signature = createSignature();
    const responseApi = await axios.post(packageJson.apiUrl + "Auth/KycRequest", userData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return responseApi.data;
}

export const kycRequestAction = async (userData: any) => {

    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl + "Auth/KycAction", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const updateUser = async (userData: any) => {

    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl + "Auth/UpdateUser", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const resetPassword = async (userData: any) => {

    const signature = createSignature();
    const response = await axios.post(packageJson.apiUrl + "Auth/ResetPassword", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}

export const dashboard = async (request: number = 1) => {

    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl + "Auth/Dashboard?DashboardRequest=" + request, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': 0
        },
    });
    return response.data;
}