import Header from "../components/Header";
import "./Design/Dashboard.css"
import "./Courses/MyCourses.css"
import { useEffect, useRef, useState } from "react";
import { dashboard } from "../handler/LoginAndSingupHandler";
import courseImage from "src/Common/CourseImage";

const Courses = () => {
    const [loader, setLoader] = useState(true);
    const [activeFilter, setActiveFilter] = useState(1);
    const [packages, setPackages] = useState([
        {
            courseId: 0,
            courseName: "",
            packageId: 0,
            courseDescription: "",
            coursePrice: 0
        }
    ]);

    const categories = [
        'All',
        'Elite Package',
        'Basic Package',
        'Advanced Package',
        'Silver Package',
        'Gold Package',
        'Platinum Package'
    ];

    const handleFilter = (category: any) => {
        setActiveFilter(category);
    };
    const getCourses = async () => {
        var response = await dashboard(2);
        if (response.statusCode === 200) {
            setPackages(response.data.dashboard.courses);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getCourses();
            runValue.current = true;
        }
    }, [runValue]);
    return <>
        <Header />
        <section className="headerBackground" style={{ padding: "10px" }}>
            <div className="filters-group mb-2 mb-4">
                {categories.map((category, index) => (
                    <button
                        key={category}
                        className={`btn-filter ${activeFilter === index + 1 ? 'active' : ''}`}
                        onClick={() => handleFilter(index + 1)}
                        style={{ color: "white" }}
                    >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </button>
                ))}
            </div>
            <ul className="list-group" style={{ gap: "20px", padding: "0" }}>
    {packages
        .filter(data => activeFilter == 1 ? data.packageId > 0 : data.packageId === activeFilter - 1)
        .sort((a, b) => b.packageId - a.packageId)
        .map((course) => (
            <>
                <div
                    style={{
                        width: "100%",
                        height: "auto",
                        padding: "0",
                        position: "relative",
                        display: "block",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Adds a shadow for a floating effect
                        borderRadius: "10px", // Smooth corners
                        overflow: "hidden", // Ensures the content stays within the card
                        transition: "transform 0.3s ease-in-out", // Adds a hover animation
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")} // Hover animation: enlarges the card slightly
                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} // Reset the card
                >
                    <div>
                        <img
                            className="img-fluid posterShow"
                            src={courseImage.find(data => data.courseId === course.courseId)?.posterLink}
                            alt={course.courseName}
                        />
                    </div>
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "20px",
                            textAlign: "center",
                            color: "black",
                        }}
                    >
                        <h2
                            style={{
                                textAlign: "center",
                                background: "linear-gradient(to right, #6a11cb, #2575fc)", // Gradient background
                                color: "white",
                                padding: "10px",
                                borderRadius: "8px",
                                fontSize: "24px",
                                margin: "0 auto",
                            }}
                        >
                            {course.courseName}
                        </h2>
                        <h4
                            style={{
                                textAlign: "center",
                                color: "#444",
                                margin: "15px 0",
                                fontSize: "18px",
                                fontWeight: "normal",
                            }}
                        >
                            {course.courseDescription}
                        </h4>
                        <p
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                fontWeight: "bold",
                                background: "linear-gradient(to right, #ff7e5f, #feb47b)", // Gradient background for the price
                                height: "50px",
                                margin: "10px auto",
                                borderRadius: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "20px",
                                maxWidth: "200px",
                            }}
                        >
                            Price: ₹{course.coursePrice}
                        </p>
                    </div>
                </div>
                <br />
            </>
        ))}
</ul>


        </section>
    </>
};

export default Courses;