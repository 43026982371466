import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "./Design/Dashboard.css"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Pannel = () => {
    const navigate = useNavigate();
    const logoutUser = () => {
        localStorage.clear();
        window.location.href = "/";
    }
    const [packageId, setPackageId] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [growniverseId, setGrowniverseId] = useState("");
    const hasRun = useRef(false);
    useEffect(() => {

        if (!hasRun.current) {
            const appKey = localStorage.getItem("appkey");
            if (appKey === null) {
                window.location.href = "/";
            }
            else {
                const storedProfile = localStorage.getItem("userProfile");
                const userProfile = JSON.parse(storedProfile ?? "");
                setIsAdmin(userProfile.isAdmin)
                setPackageId(userProfile.packageId);
                setGrowniverseId(userProfile.growniverseId);
            }
            hasRun.current = true;
        }
    }, [hasRun])
    return <>
        <section style={{ background: "#343434" }}>
            <nav className="navbar navbar-static-top navbar-expand-lg px-3 px-md-5">
                <div className="container-fluid position-relative px-0">
                    <button
                        className="navbar-toggler"
                        style={{ border: "2px solid white" }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse"
                    >
                        <i className="fas fa-align-left" style={{ color: "white" }}></i>
                    </button>

                    <div className="navbar-collapse collapse">
                        <ul className="navbar-nav me-auto headerBackground">
                            {
                                !isAdmin && (<>
                                    {
                                        packageId > 0 && (<>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/dashboard'); }}>
                                                    Dashboard
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/payouts'); }}>
                                                    Payouts
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/profile'); }}>
                                                    Profile
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/kyc'); }}>
                                                    My Kyc
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/mycourses'); }}>
                                                    My Courses
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/mywallet'); }}>
                                                    My Wallet
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/team'); }}>
                                                    My Team
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/training'); }}>
                                                    Training
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/affiliatelink'); }}>
                                                    Affiliate Link
                                                </Button>
                                            </li>
                                            <li>
                                                <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/tools'); }}>
                                                    Tools
                                                </Button>
                                            </li>
                                        </>)
                                    }
                                </>)
                            }
                            {
                                isAdmin && (<>

                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/packagepayment'); }}>
                                            Package Payment
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/admin'); }}>
                                            Turnover
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/kycdetail'); }}>
                                            Kyc Detail
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/withdrawrequests'); }}>
                                            WithDraw Requests
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/userdetail'); }}>
                                            User Details
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/updateuser'); }}>
                                            Update User Detail
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/admincourses'); }}>
                                            Growniverse Courses
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/adminwallet'); }}>
                                            Admin Wallet
                                        </Button>
                                    </li>
                                    <li>
                                        <Button data-bs-toggle="collapse" data-bs-target=".navbar-collapse" style={{ color: "white", marginRight: "20px" }} onClick={() => { navigate('/qualityUser'); }}>
                                            Team View
                                        </Button>
                                    </li>
                                </>)
                            }
                            <li>
                                <Button onClick={(logoutUser)} style={{ border: "1px solid #DBBC66" }}>
                                    <b>Logout</b>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    </>
};

export default Pannel;