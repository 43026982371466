import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { getAdminPannel } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';
import { AddUpdateCourse } from '../handler/coursehandler';

const AdminCourses = () => {
    const [loader, setLoader] = useState(false);
    const [addUpdate, setAddUpdate] = useState("Add Course");
    const [pannel, setPannel] = useState("showcourses");
    type Course = {
        courseId: number;
        courseName: string;
        courseDescription: string;
        packageId: number;
        status: string;
        coursePrice: number;
    };
    const [updateCourseData, setUpdateCourseData] = useState({
        "courseId": 0,
        "courseName": "",
        "courseDescription": "",
        "packageId": 1,
        "coursePrice": 0,
    })

    const getCourses = async () => {
        setLoader(true);
        var response = await getAdminPannel(5);
        if (response.statusCode === 200) {
            const coursesDetails = response.data.adminPannel.courses;
            setCourses(coursesDetails);
            setFilterData(coursesDetails);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getCourses();
            runValue.current = true;
        }
    }, [runValue]);
    const [Courses, setCourses] = useState([
        {
            "courseId": 0,
            "courseName": "string",
            "courseDescription": "string",
            "packageId": 0,
            "coursePrice": 0,
            "status": "string"
        }
    ]);
    const [CourseVideo, setCourseVideo] = useState(
        {
            "courseId": 0,
            "courseName": "string",
            "courseDescription": "string",
            "packageId": 0,
            "status": "string"
        }
    );
    const [filterData, setFilterData] = useState([
        {
            "courseId": 0,
            "courseName": "string",
            "courseDescription": "string",
            "packageId": 0,
            "coursePrice": 0,
            "status": "string"
        }
    ]);

    const packages = [
        { packageId: 0, packageName: "All" },
        { packageId: 1, packageName: "Elite Package" },
        { packageId: 2, packageName: "Basic Package" },
        { packageId: 3, packageName: "Advanced Package" },
        { packageId: 4, packageName: "Silver Package" },
        { packageId: 5, packageName: "Gold Package" },
        { packageId: 6, packageName: "Platinum Package" },
    ];

    const search = (event: any) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = Courses.filter((data) =>
            data.courseName.toLowerCase().includes(searchTerm)
        );
        setFilterData(filtered);
    }
    const handelOnChange = (name: string, e: any) => {
        const value = e.target.value;
        setUpdateCourseData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const AddUpdate = async (event: any) => {
        event.preventDefault();
        setLoader(true);
        await AddUpdateCourse(updateCourseData);
        setPannel("showcourses");
        await getCourses();
    }
    const UpdateCourse = async (userData: Course) => {
        setUpdateCourseData({
            "courseId": userData.courseId,
            "courseName": userData.courseName,
            "courseDescription": userData.courseDescription,
            "packageId": userData.packageId,
            "coursePrice": userData.coursePrice
        });
        setAddUpdate("Update Course");
        setPannel("addupdatecourses");
    }

    const CourseVideos = async (userData: Course) => {
        setUpdateCourseData({
            "courseId": userData.courseId,
            "courseName": userData.courseName,
            "courseDescription": userData.courseDescription,
            "packageId": userData.packageId,
            "coursePrice": userData.coursePrice
        });
        setCourseVideo(userData);
        setPannel("showcoursevideos");
    }

    const AddCourse = () => {
        setAddUpdate("Add Course");
        setUpdateCourseData({
            "courseId": 0,
            "courseName": "",
            "courseDescription": "",
            "packageId": selectedPackage,
            "coursePrice": 0
        });
        setPannel("addupdatecourses");
    }
    const calculateGst = (price: number) => {
        return Math.round(Number(price) + price * 0.18)
    }
    const [selectedPackage, setSelectedPackage] = useState(1);
    return (
        <>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <>
                        {
                            pannel == "showcourses" ?
                                <section className='headerBackground'>
                                    <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                        <TableContainer style={{ background: "white" }}>
                                            <h3 style={{ textAlign: "center" }}>Growniverse Courses</h3>
                                            <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Input placeholder='Search' onChange={search} />
                                            </div>
                                            <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <select
                                                    id="packageDropdown"
                                                    value={selectedPackage}
                                                    onChange={(e) => { setSelectedPackage(parseInt(e.target.value)) }}
                                                    style={{ padding: "10px", border: "1px solid #343434", borderRadius: "10px", color: "#343434", fontWeight: "bold" }}
                                                    className='headerBackground'
                                                >
                                                    <option value="" disabled>
                                                        Select a package
                                                    </option>
                                                    {packages.map((pkg) => (
                                                        <option key={pkg.packageId} value={pkg.packageId}>
                                                            {pkg.packageName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div style={{ padding: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Button style={{ background: "#B4E28E", padding: "10px", borderRadius: "10px" }} onClick={AddCourse}>Add Course</Button>
                                            </div>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>CoursesId</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Courses Name</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Course Description</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Price</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Price With Tax</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Package Id</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Action</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>Action Course Videos</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {filterData.map(statement => (
                                                    (statement.packageId === selectedPackage || selectedPackage === 0) && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center">
                                                                    {statement.courseId}
                                                                </TableCell>
                                                                <TableCell align="center">{statement.courseName}</TableCell>
                                                                <TableCell align="center">{statement.courseDescription}</TableCell>
                                                                <TableCell align="center">{statement.coursePrice}</TableCell>
                                                                <TableCell align="center">{calculateGst(statement.coursePrice)}</TableCell>
                                                                <TableCell align="center">
                                                                    {packages.find(data => data.packageId === statement.packageId)?.packageName}</TableCell>
                                                                <TableCell align="center">
                                                                    <Button style={{ background: "lightblue", borderRadius: "10px" }} onClick={() => { UpdateCourse(statement) }}>Update</Button>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Button style={{ background: "lightblue", borderRadius: "10px" }} onClick={() => { CourseVideos(statement) }}>Course Videos</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )
                                                ))}
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </section> :
                                pannel == "addupdatecourses" ?
                                    <section style={{ padding: "15% 50px 50px 50px", width: "100%", height: "100%", position: "fixed", top: "0", background: "rgba(0, 0, 0, .8)", overflowY: 'scroll', }}>
                                        <div style={{ background: "#3434", height: "80%" }}>
                                            <form onSubmit={AddUpdate}>
                                                <TableContainer style={{ background: "white" }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                                Course Name
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Input placeholder='Course Name' value={updateCourseData.courseName} onChange={(e) => { handelOnChange("courseName", e) }} required />
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableHead>
                                                            <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                                Course Description
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <TextareaAutosize value={updateCourseData.courseDescription} onChange={(e) => { handelOnChange("courseDescription", e) }} placeholder='Course Description' required />
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableHead>
                                                            <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                                Package Id
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <div>
                                                                    <select
                                                                        id="packageDropdown"
                                                                        value={updateCourseData.packageId}
                                                                        onChange={(e) => { handelOnChange("packageId", e) }}
                                                                        style={{ padding: "10px", border: "1px solid #343434", background: "#B4EF9D", borderRadius: "10px" }}
                                                                    >
                                                                        <option value="" disabled>
                                                                            Select a package
                                                                        </option>
                                                                        {packages.map((pkg) => (
                                                                            <option key={pkg.packageId} value={pkg.packageId}>
                                                                                {pkg.packageName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableHead>
                                                            <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                                Course price
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Input value={updateCourseData.coursePrice} onChange={(e) => { handelOnChange("coursePrice", e) }} type='number' />
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableHead>
                                                            <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                                Course price With Tax
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <h5>{calculateGst(updateCourseData.coursePrice)}</h5>
                                                            </TableCell>
                                                        </TableHead>
                                                    </Table>
                                                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", paddingTop: "40px" }}>
                                                        <Button style={{ background: "red", color: "white", padding: "10px", borderRadius: "10px" }} onClick={() => setPannel("showcourses")}>Close</Button>
                                                        <Button className='headerBackground' style={{ color: "white", padding: "10px", borderRadius: "10px" }} type='submit'>{addUpdate}</Button>
                                                    </div><br />
                                                </TableContainer>
                                            </form>
                                        </div>
                                    </section> :
                                    pannel === "showcoursevideos" ?
                                        <>
                                            <section className='headerBackground'>
                                                <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                                    <TableContainer style={{ background: "white" }}>
                                                        <h3 style={{ textAlign: "center" }}>{CourseVideo.courseName} Courses Videos</h3>
                                                        <div style={{ padding: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                        </div>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>CoursesId</TableCell>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>Courses Name</TableCell>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>Course Description</TableCell>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>Package Id</TableCell>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>Action</TableCell>
                                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>Action Course Videos</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            {filterData.map(statement => (
                                                                (statement.packageId === selectedPackage || selectedPackage === 0) && (
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="center">
                                                                                {statement.courseId}
                                                                            </TableCell>
                                                                            <TableCell align="center">{statement.courseName}</TableCell>
                                                                            <TableCell align="center">{statement.courseDescription}</TableCell>
                                                                            <TableCell align="center">
                                                                                {packages.find(data => data.packageId === statement.packageId)?.packageName}</TableCell>
                                                                            <TableCell align="center">
                                                                                <Button style={{ background: "lightblue", borderRadius: "10px" }} onClick={() => { UpdateCourse(statement) }}>Update</Button>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Button style={{ background: "lightblue", borderRadius: "10px" }} onClick={() => { UpdateCourse(statement) }}>Course Videos</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                )
                                                            ))}
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </section>
                                        </> : <></>
                        }
                    </>
                )
            }
        </>
    );
};

export default AdminCourses;
