import DashboardProfile from '../Dashboard/DashboardProfile';
import "../Dashboard/Design/Dashboard.css"
import Income from '../Dashboard/Income';
import "../components/WelcomeSection.css";
import { useEffect, useRef, useState } from "react";
import UserLoader from '../Dashboard/UserLoader';
const Admin = () => {
    const [loader, setLoader] = useState(true);
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "",
        sponsorGrowniverseId: "",
        growniverseId: "",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false,
        profileImage: "",
        packageRequest:false
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const [accountData, setAccountData] = useState({
        accountId: 1,
        unPaidBalance: 0,
        totalIncome: 0,
        todayIncome: 0,
        sevenDayIncome: 0,
        monthlyIncome: 0,
        sevenDayTurnover: 0,
        monthlyTurnover: 0,
        totalTurnover: 0,
        totalPaid: 0,
        adminOutstandingPayments: 0,
        taxPayment: 0
    });
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const storedAccount = localStorage.getItem("account");
            const userProfile = JSON.parse(storedProfile ?? "");
            const account = JSON.parse(storedAccount ?? "");
            setUserProfile(userProfile);
            setAccountData(account);
            runOnce.current = true;
            setLoader(false);
        }
    }, [runOnce]);
    return (
        <>
            <section style={{ background: "#343434" }}>
                {loader && (
                    <UserLoader />
                )}
                {
                    !loader && (
                        <>
                            <DashboardProfile userProfile={userProfile} totalIncome={accountData.totalIncome}/>
                            <Income account={accountData} pageName="dashboard" isAdmin={true} />
                        </>
                    )
                }
            </section>
        </>
    );
};

export default Admin;
