import React, { useEffect, useRef, useState } from 'react';
import aboutImg from '../assets/img/about.jpg';
interface InnerBannerProps {
  statistics: {
    students: number;
    courses: number;
    teachers: number;
    awards: number;
  };
}

const AboutSection: React.FC<InnerBannerProps> = ({ statistics }) => {
  const [counters, setCounters] = useState({
    students: 0,
    courses: 0,
    teachers: 0,
    awards: 0,
  });

  const animatedCounters = useRef({
    students: Math.random() * statistics.students, // Start from a random value
    courses: Math.random() * statistics.courses,
    teachers: Math.random() * statistics.teachers,
    awards: Math.random() * statistics.awards
  });

  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const targetValues = statistics;
    const duration = 2000; // Animation duration in milliseconds
    const startTime = performance.now();

    const animateCounters = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const newValues = {
        students: animatedCounters.current.students + progress * (targetValues.students - animatedCounters.current.students),
        courses: animatedCounters.current.courses + progress * (targetValues.courses - animatedCounters.current.courses),
        teachers: animatedCounters.current.teachers + progress * (targetValues.teachers - animatedCounters.current.teachers),
        awards: animatedCounters.current.awards + progress * (targetValues.awards - animatedCounters.current.awards),
      };
      setCounters({
        students: Math.round(newValues.students),
        courses: Math.round(newValues.courses),
        teachers: Math.round(newValues.teachers),
        awards: Math.round(newValues.awards),
      });
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateCounters);
      }
    };

    animationRef.current = requestAnimationFrame(animateCounters);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [statistics]);
  return (
    <section className="space-ptb bg-f9">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img className="img-fluid" alt="" src={aboutImg} />
          </div>
          <div className="col-md-6">
            <h2>About Us</h2>
            <p className="mb-4">
              Growniverse is your Digital Learning Platform Where you can learn new knowledge and skills without any physical distance barrier. You just not only get classes but you will get live webinars and specially Real Life Experience of Entrepreneurship.
            </p>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3 mb-md-0 mb-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="2208" data-speed="2000">{counters.students}</h3>
                  </div>
                  <p className="mb-0 fw-6">Happy Students</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 mb-md-0 mb-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="250" data-speed="2000">{counters.courses}+</h3>
                  </div>
                  <p className="mb-0 fw-6">Our Courses</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="130" data-speed="2000">{counters.teachers}</h3>
                  </div>
                  <p className="mb-0 fw-6">Our Teachers</p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div className="counter">
                  <div className="counter-number">
                    <h3 className="timer" data-to="26" data-speed="2000">{counters.awards}</h3>
                  </div>
                  <p className="mb-0 fw-6">Awards Won</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
