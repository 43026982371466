import axios from "axios";
const packageJson = require('../../package.json');
const createSignature = () => {
    const secretKey = "5d928cd2-77d1-4415-abbb-4f225f40ee65"
    return secretKey;
}

export const GetUserCourses = async (packageId: number,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl+"Courses?PackageId="+packageId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const AddUpdateCourse = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl+"Courses/AddUpdate",userData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const GetPackages = async (userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl+"Courses/Packages", {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const GetCourseDetails = async (courseId:number,userInformationId : number = 0) => {
    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl+"Courses/CourseDetail?courseId="+courseId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const GetLearningUrl = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.post(packageJson.apiUrl+"Courses/CourseVideo",userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const LearningCourse = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.post(packageJson.apiUrl+"Courses/LearningCourse",userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}