import React, { useEffect, useRef, useState } from "react";
import "../../Dashboard/Design/Dashboard.css"
import { dashboard } from "../../handler/LoginAndSingupHandler";
import { Button } from "@mui/material";
import courseImage from "src/Common/CourseImage";

interface InnerBannerProps {
  packageId: number;
}
const CourseInfoWidget: React.FC<InnerBannerProps> = ({ packageId }) => {
  const runOnce = useRef(false);
  const [packages, setPackages] = useState([
    {
      courseId: 0,
      courseName: "",
      packageId: 0,
      courseDescription: ""
    }
  ]);
  const getCourses = async () => {
    var response = await dashboard(2);
    if (response.statusCode === 200) {
      setPackages(response.data.dashboard.courses);
    }
  }
  const [showPackageInfo, setShowPackageInfo] = useState(true);
  useEffect(() => {
    if (runOnce.current === false) {
      runOnce.current = true;
      const storedProfile = localStorage.getItem("userProfile");
      const userProfile = JSON.parse(storedProfile ?? "");
      getCourses();
      runOnce.current = true;
    }
  }, [runOnce])
  return (
    <div className="widget widget-course-info">
      <Button onClick={() => { setShowPackageInfo(!showPackageInfo) }} style={{ border: "1px solid black", margin: "10px", borderRadius: "10px", gap: "5px", color: "black" }}>
        Package Info
        {
          showPackageInfo ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>
        }
      </Button>
      {
        showPackageInfo &&
        <>
          {packages.filter(data => data.packageId === 6 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Platinum Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}

          {packages.filter(data => data.packageId === 5 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Gold Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}

          {packages.filter(data => data.packageId === 4 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Silver Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}

          {packages.filter(data => data.packageId === 3 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Advanced Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}

          {packages.filter(data => data.packageId === 2 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Basic Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}

          {packages.filter(data => data.packageId === 1 && data.packageId <= packageId)
            .sort((a, b) => b.packageId - a.packageId)
            .map((course, index) => (
              <>
                {index === 0 && <h5 style={{ textAlign: "center", background: "#0F4548", color: "white", fontSize: "20px", padding: "10px", borderRadius: "20px", border: "2px solid #DBBC66" }}>Elite Package</h5>}
                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                <div
                  className='slide-item px-3 boxblockorflex'
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0",
                    position: "relative",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <img className="imageInPackage" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                      width: "100%",
                      textAlign: "left",
                      color: "black"
                    }}
                  >
                    <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>{course.courseDescription}</h5>
                  </div>
                </div><br /><br />
              </>
            ))}


        </>
      }
    </div>
  );
};

export default CourseInfoWidget;
