import "./Design/Dashboard.css"
import { useEffect, useRef, useState } from "react";
import "./Design/Dashboard.css"
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";
import businessPdf from "../Documents/Tools/BusinessPlanPDF.pdf";
import businessPpt from "../Documents/Tools/BusinessPlanPPT.pptx";
import trainingImage from "../Photos/Training/First Step.png"
import "../Dashboard/Courses/MyCourses.css"

const Training = () => {
    const [growniverseId, setGrowniverseId] = useState("");
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setGrowniverseId(userProfile.growniverseId);
            runOnce.current = true;
        }
    }, [runOnce]);
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    const handleShare = async () => {
        try {
            const shareData = {
                title: 'Growniverse',
                text: 'Register Link :- ',
                url: link,
            };
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                alert('Web Share API is not supported in your browser.');
            }
        } catch (error) {
            console.error('Error sharing content:', error);
        }
    };
    const link = `https://growniverse.com/?affiliatelink=${growniverseId}`;
    const handleCopy = () => {
        navigator.clipboard.writeText(link).then(() => {
        }).catch((error) => {
        });
    };
    const downloadPdf = () => {
        window.open(businessPpt, "_blank");
    }
    const [videoPlay, setVideoPlay] = useState("training");
    return <>
        <>
            <section className="headerBackgroundReverse" style={{ padding: "30px", background: "" }}>
                <h3 style={{ textAlign: "center", color: "white", background: "black", padding: "10px", borderRadius: "8px" }}>Training</h3>
                <div style={{ background: "white", borderRadius: "10px", padding: "10px" }}>
                    {
                        videoPlay === "training" &&
                        <>
                            <div>
                                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>First Step Meeting</h5>
                                <Button onClick={() => { setVideoPlay("firstStep") }}
                                    className='slide-item px-3'
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        padding: "0",
                                        position: "relative",
                                        display:"block",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div>
                                        <img className="trainingImage" src={trainingImage} />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                                            width: "100%",
                                            textAlign: "left",
                                            color: "black"
                                        }}
                                    >
                                        <h5 style={{ textAlign: "center", background: "", color: "black", fontSize: "20px" }}>
                                            Welcome to Growniverse! 'First Step to Success' guides you through the essential actions to take immediately after enrollment, setting you up for a transformative journey.
                                        </h5>
                                    </div>
                                    <h5 style={{color:"green"}}>Start First Step</h5>
                                </Button>
                            </div>
                        </>
                    }
                    {
                        videoPlay === "firstStep" &&
                        <>
                            <Button onClick={() => { (setVideoPlay("training")); }} style={{ borderRadius: "20px", color: "white", background: "#343434" }}><i className="fa-solid fa-arrow-left"></i></Button>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div
                                    style={{
                                        position: "relative",
                                        display: "inline-block",
                                        width: "80%",
                                        maxWidth: "100%",
                                        border: "2px solid #ddd",
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    <video
                                        autoPlay
                                        controls
                                        controlsList="nodownload"
                                        onContextMenu={(e) => e.preventDefault()}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            border: "none",
                                        }}
                                    >
                                        <source
                                            src="https://api.growniverse.com/api/Video/stream" // Replace with your .NET backend endpoint
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        </>
    </>
};

export default Training;