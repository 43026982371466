import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { getAdminPannel } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';
import { WithDrawRequestAction } from '../handler/accountHandler';

const WithDrawRequestApproval = () => {
    const transactionRequests = [
        {
            "fullName": "",
            "growniverseId": "",
            "transactionId": 0,
            "amount": 0,
            "transactionTime": "",
            "status": 1
        }
    ];
    type transaction = {
        fullName:string,
        growniverseId:string,
        transactionId: number,
        amount: number,
        transactionTime: string,
        status: number,
    };
    const [pendingRequest, setPendingRequest] = useState(transactionRequests);
    const [succesfullRequest, setSuccesfullRequest] = useState(transactionRequests);
    const [transactionUserDetail, setTransactionUserDetail] = useState(transactionRequests);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event:any,newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Slice data for current page
    const paginatedData = succesfullRequest.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = isPM ? "PM" : "AM";
        const formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
        return <div>{formattedDateTime}</div>;
    };
    const [loader, setLoader] = useState(false);
    const approveTransactionRequest = async (transactionId: number, accept: number) => {
        setLoader(true);
        await WithDrawRequestAction({
            "transationId": transactionId,
            "status": accept
          });
        window.location.reload();
    }
    var runValue = useRef(false);
    const filterTasksByStatus = (transactionRequests:any,status: number) => {
        return transactionRequests.filter((task:transaction) => task.status === status);
    };
    const filterTasksByNotStatus = (transactionRequests:any,status: number) => {
        return transactionRequests.filter((task:transaction) => task.status !== status).sort((a:transaction, b:transaction) => a.transactionTime > b.transactionTime ? -1 : 1);
    };
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    const getRequest = async () => {
        setLoader(true);
        var response = await getAdminPannel(3);
        if (response.statusCode === 200) {
            const transactionRequests = response.data.adminPannel.transactionRequests;
            setPendingRequest(filterTasksByStatus(transactionRequests, 1));
            setSuccesfullRequest(filterTasksByNotStatus(transactionRequests, 1));
            setTransactionUserDetail(filterTasksByNotStatus(transactionRequests, 1));
        }
        setLoader(false);
    }
    useEffect(() => {
        if (runValue.current === false) {
            getRequest();
            runValue.current = true;
        }
    }, [runValue]);

    const search = (event:any) =>{
        const searchTerm = event.target.value.toLowerCase();
        const filtered = transactionUserDetail.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
        data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setSuccesfullRequest(filtered);
        setPage(0); // Reset to first page
    }
    return (
        <>
            <section className='headerBackground'>
                {
                    loader && (
                        <UserLoader />
                    )
                }
                {
                    !loader && (
                        <>
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                <TableContainer style={{ background: "white" }}>
                                    <h3 style={{ textAlign: "center" }}>Transaction Request</h3>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Sr.No</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UserDetails</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>TransactionId</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Amount</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Transaction Time</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {pendingRequest.map((transaction,index) => (
                                            <TableBody>
                                                <TableRow>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell  align="center" style={{ fontWeight: "bold" }}>
                                                        {transaction.fullName}<br/>
                                                        {transaction.growniverseId}
                                                    </TableCell>
                                                    <TableCell  align="center" style={{ fontWeight: "bold" }}>
                                                        {transaction.transactionId}
                                                    </TableCell>
                                                    <TableCell  align="center" style={{ fontWeight: "bold" }}>
                                                        {formatRupees(transaction.amount)}
                                                    </TableCell>
                                                    <TableCell  align="center" style={{ fontWeight: "bold" }}>
                                                        {DateTimeDisplay(transaction.transactionTime)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ justifyContent: "space-between", gap: "10px" }}>
                                                        <Button style={{ border: "1px solid green", background: "green", color: "white" }} onClick={() => approveTransactionRequest(transaction.transactionId, 1)}>Approve</Button><br /><br /><br />
                                                        <Button style={{ border: "1px solid red", background: "red", color: "white" }} onClick={() => approveTransactionRequest(transaction.transactionId, 2)}>Reject</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20]}
                                        component="div"
                                        count={pendingRequest.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </div>
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                    <h3 style={{ textAlign: "center" }}>Transaction Details</h3>
                                    <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Input placeholder='Search' onChange={search} />
                                    </div>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Sr.No</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>UserDetails</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>TransactionId</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Amount</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Transaction Time</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {paginatedData.map((transaction,index) => (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {transaction.fullName}<br/>
                                                        {transaction.growniverseId}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {transaction.transactionId}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {formatRupees(transaction.amount)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {DateTimeDisplay(transaction.transactionTime)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        <span style={transaction.status === 2 ?{color:"green"} : {color:"red"}}>
                                                            {transaction.status === 2 ?"Approved" : "Rejected"}
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20]}
                                        component="div"
                                        count={succesfullRequest.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};

export default WithDrawRequestApproval;
