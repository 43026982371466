import React, { useEffect, useRef, useState } from 'react';
import HomeBanner from '../components/home-banner/HomeBanner';
import FeatureInfo from '../components/FeatureInfo';
import CourseCategoryList from '../components/course/CourseCategoryList';
import PackageList from '../components/PackageList';
import WelcomeSection from '../components/WelcomeSection';
import StatisticsSection from '../components/StatisticsSection';
import AchieversSection from '../components/AchieversSection';
import Testimonials from '../components/Testimonials';
import Header from '../components/Header';
import { dashboard } from '../handler/LoginAndSingupHandler';
import CourseList from '../components/CourseList';
import UserLoaderVideo from '../Dashboard/UserLoaderVideo';

const HomePage = () => {
    const [loader, setLoader] = useState(true);
    const [statistics, setStatistics] = useState({
        students: 0,
        courses: 0,
        teachers: 0,
        awards: 0,
      });
    const runOnce = useRef(false);
    const dashboardApi = async () => {
        var response = await dashboard();
        if (response.statusCode === 200) {
            setStatistics(response.data.dashboard.statistics);
        }
        setTimeout(() => {
            setLoader(false);
        }, 3000);
    }
    useEffect(() => {
        if (runOnce.current === false) {
            dashboardApi();
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        {
            loader ? <UserLoaderVideo /> :
                <>

                    <Header />
                    <HomeBanner />
                    <FeatureInfo />
                    <WelcomeSection />
                    <CourseCategoryList />
                    <StatisticsSection statistics={statistics}/>
                    <PackageList />
                    <AchieversSection />
                    <CourseList />
                    <Testimonials />
                </>
        }
    </>
};

export default HomePage;