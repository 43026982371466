import "./Design/Dashboard.css"
import Income from "./Income";
import DashboardProfile from "./DashboardProfile";
import { useEffect, useRef, useState } from "react";
import HeigherEarnerOfTheWeek from "./HeighestEarnerOfTheWeek";
import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const Dashboard = () => {
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false,
        profileImage: "",
        status: 0,
        registrationDate: "",
        packageRequest: false
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const [accountData, setAccountData] = useState({
        accountId: 1,
        unPaidBalance: 0,
        totalIncome: 0,
        todayIncome: 0,
        sevenDayIncome: 0,
        monthlyIncome: 0,
        sevenDayTurnover: 0,
        monthlyTurnover: 0,
        totalTurnover: 0,
        totalPaid: 0,
        adminOutstandingPayments: 0,
        taxPayment: 0
    });
    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);

        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();

        const formattedDate = `${day} ${monthName} ${year}`;

        return <div>{formattedDate}</div>;
    };
    const [showBanner, setShowBanner] = useState(true);
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const storedAccount = localStorage.getItem("account");
            const userProfile = JSON.parse(storedProfile ?? "");
            const account = JSON.parse(storedAccount ?? "");
            setUserProfile(userProfile);
            setAccountData(account);
            runOnce.current = true;
        }
    }, [runOnce]);
    return <>

        <DashboardProfile userProfile={userProfile} totalIncome={accountData.totalIncome} />
        <Income account={accountData} pageName="dashboard" isAdmin={false} />
        <div className="monthIncome" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "40px" }}>
            <TableContainer style={{ background: "smokewhite", borderRadius: "30px", border: "3px solid #b77f5a" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ color: "white" }}>Registration Date</TableCell>
                            <TableCell align="center" style={{ color: "white" }}>{DateTimeDisplay(userProfile.registrationDate)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ color: "white" }}>Kyc Status</TableCell>
                            <TableCell align="center" style={{ color: "white" }}>{userProfile.status <= 4 ? "Pending" : userProfile.status === 5 ? "Requested" : "Active"}</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </div>
        <HeigherEarnerOfTheWeek />
    </>
};

export default Dashboard;