import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { actionBeneficiary, paymentRequestAction } from "../handler/accountHandler"
import UserLoader from '../Dashboard/UserLoader';
import { getAdminPannel } from '../handler/LoginAndSingupHandler';

const PackagePaymentRequest = () => {
    const isRun = useRef(false);
    type packagePayment = {
        packagePaymentId: number,
        transactionId: number,
        packageName: string,
        payment: number,
        status: number,
        paymentDate: string,
        growniverseId: string,
        sponsorGrowniverseId: string,
        fullName: string,
        imageData: string,
        beneficiaryId: number,
        beneficiaryName: string,
        beneficiaryUserInformationId: string,
        beneficiaryStatus: number,
        beneficiaryGrowniverseId: string
    };
    const packagePayments = [{
        packagePaymentId: 0,
        transactionId: 0,
        packageName: "",
        payment: 0,
        status: 0,
        paymentDate: "",
        growniverseId: "",
        sponsorGrowniverseId: "",
        fullName: "",
        imageData: "",
        beneficiaryId: 0,
        beneficiaryName: "",
        beneficiaryUserInformationId: "",
        beneficiaryStatus: 0,
        beneficiaryGrowniverseId: ""
    }];
    const [packagePaymentDetail, setPackagePaymentDetail] = useState({
        packagePaymentId: 0,
        transactionId: 0,
        packageName: "",
        payment: 0,
        status: 0,
        paymentDate: "",
        growniverseId: "",
        sponsorGrowniverseId: "",
        fullName: "",
        imageData: "",
        beneficiaryId: 0,
        beneficiaryName: "",
        beneficiaryUserInformationId: "",
        beneficiaryStatus: 0,
        beneficiaryGrowniverseId: ""
    });
    const [showBeneficiaryModule, setBeneficiaryModule] = useState(false);
    const filterTasksByStatus = (packageRequest: any, status: number) => {
        return packageRequest.filter((task: packagePayment) => task.status === status);
    };
    const filterTasksByNotStatus = (packageRequest: any, status: number) => {
        return packageRequest.filter((task: packagePayment) => task.status !== status).sort((a: packagePayment, b: packagePayment) => a.paymentDate > b.paymentDate ? -1 : 1);
    };
    const getPackagePayment = async () => {
        setLoader(true);
        var response = await getAdminPannel();
        if (response.statusCode === 200) {
            const packageRequest = response.data.adminPannel.packagePayments;
            setPendingRequest(filterTasksByStatus(packageRequest, 1));
            setSuccesfullRequest(filterTasksByNotStatus(packageRequest, 1));
            setSuccesfullRequestToShow(filterTasksByNotStatus(packageRequest, 1))
        }
        setLoader(false);
    }
    useEffect(() => {
        if (isRun.current === false) {
            getPackagePayment();
            isRun.current = true;
        }
    }, [isRun])
    const [pendingRequest, setPendingRequest] = useState(packagePayments);
    const [succesfullRequest, setSuccesfullRequest] = useState(packagePayments);
    const [succesfullRequestToShow, setSuccesfullRequestToShow] = useState(packagePayments);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedDataForPending = pendingRequest.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const paginatedDataForsuccesfull = succesfullRequestToShow.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = isPM ? "PM" : "AM";

        const formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;

        return <div>{formattedDateTime}</div>;
    };

    const handelPaymentRequestAction = async (paymentId: number, accept: number) => {
        setLoader(true);
        await paymentRequestAction({
            "packagePaymentId": paymentId,
            "paymentAccept": accept
        });
        await getPackagePayment();
    }
     
    const handelbeneficiaryRequestAction = async (beneficiaryId: number, accept: number) => {
        setBeneficiaryModule(false);
        setLoader(true);
        await actionBeneficiary({
            "beneficiaryId": beneficiaryId,
            "isAccepted": accept === 1 ? true : false
        });
        await getPackagePayment();
    }

    const search = (event: any) => {
        
        const searchTerm = event.target.value.toLowerCase();
        const filtered = succesfullRequest.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
            data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setSuccesfullRequestToShow(filtered);
        setPage(0); // Reset to first page
    }

    const beneficiaryActionModule = (packagePayment: packagePayment) => {
        setPackagePaymentDetail(packagePayment);
        setBeneficiaryModule(true);
    }

    return (
        <>
            <section className='headerBackground'>
                {loader && (
                    <UserLoader />
                )}
                {!loader && (
                    <>
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Package Payment Requested</h3>
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="center">Sr.No</TableCell>
                                            <TableCell align="center">GrowniverseId</TableCell>
                                            <TableCell align="center">SponsorGrowniverseId</TableCell>
                                            <TableCell align="center">DateTime</TableCell>
                                            <TableCell align="center">TransactionId</TableCell>
                                            <TableCell align="center">PackageName</TableCell>
                                            <TableCell align="center">Payment</TableCell>
                                            <TableCell align="center">ScreenShot</TableCell>
                                            <TableCell align="center">Beneficiary</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {paginatedDataForPending.map((payment, index) => (
                                        payment.status === 1 && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                        {payment.growniverseId}({(payment.fullName)})
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {payment.sponsorGrowniverseId}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold" }}>{DateTimeDisplay(payment.paymentDate)}</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold", fontSize: "20px" }}>{payment.transactionId}</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold", fontSize: "20px" }}>{payment.packageName}</TableCell>
                                                    <TableCell align="center" style={{ fontWeight: "bold", fontSize: "20px" }}>{payment.payment}</TableCell>
                                                    <TableCell align="center"><img src={`data:image/jpeg;base64,${payment.imageData}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} /></TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            (payment.beneficiaryId !== 0 && payment.beneficiaryStatus !== 3) ?
                                                                <>
                                                                    {payment.beneficiaryName}({payment.beneficiaryGrowniverseId})
                                                                </>
                                                                :
                                                                <>
                                                                    N/A
                                                                </>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" style={{ justifyContent: "space-between", gap: "10px" }}>
                                                        {
                                                            payment.beneficiaryStatus === 1 ?
                                                                <>
                                                                    <Button className='headerBackground' style={{ border: "1px solid green", color: "white" }} onClick={()=>{beneficiaryActionModule(payment)}}>Beneficiary Action</Button>
                                                                </> :
                                                                <>
                                                                    <Button style={{ border: "1px solid green", background: "green", color: "white" }} onClick={() => handelPaymentRequestAction(payment.packagePaymentId, 1)}>Approve</Button><br /><br /><br />
                                                                    <Button style={{ border: "1px solid red", background: "red", color: "white" }} onClick={() => handelPaymentRequestAction(payment.packagePaymentId, 2)}>Reject</Button>
                                                                </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    ))}
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20]}
                                    component="div"
                                    count={pendingRequest.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Package Payment</h3>
                                <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Input placeholder='Search' onChange={search} />
                                </div>
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="center">Sr.No</TableCell>
                                            <TableCell align="center">GrowniverseId</TableCell>
                                            <TableCell align="center">SponsorGrowniverseId</TableCell>
                                            <TableCell align="center">DateTime</TableCell>
                                            <TableCell align="center">TransactionId</TableCell>
                                            <TableCell align="center">PackageName</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {paginatedDataForsuccesfull.map((payment, index) => (
                                        payment.status != 1 && (
                                            <>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>
                                                            {payment.growniverseId}({(payment.fullName)})
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {payment.sponsorGrowniverseId}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>{DateTimeDisplay(payment.paymentDate)}</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold", fontSize: "20px" }}>{payment.transactionId}</TableCell>
                                                        <TableCell align="center" style={{ fontWeight: "bold" }}>{payment.packageName}</TableCell>
                                                        <TableCell align="center" style={payment.status === 2 ? { color: "green", fontWeight: "bold" } : { color: "red", fontWeight: "bold" }}>{payment.status === 1 ? "Requested" : payment.status === 2 ? "Succesfull" : "Rejected"}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </>
                                        )
                                    ))}
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20]}
                                    component="div"
                                    count={succesfullRequest.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    </>
                )}
            </section>
            <section>
                {
                    showBeneficiaryModule && (
                        <>
                            <section style={{ height: "100%", background: "rgba(0, 0, 0, 0.9)", position: "fixed", width: "100%", top: 0, zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button style={{ position: "fixed", top: 0, right: 0 }} onClick={() => { setBeneficiaryModule(false) }}><i className="fa fa-times" aria-hidden="true" style={{ fontSize: "20px" }}></i></Button>
                                <div style={{ background: "white", height: "80%", width: "90%", left: "5%", borderRadius: "20px", overflow: "auto" }}>
                                <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Beneficiary Action</h3>
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="center">Beneficiary Id</TableCell>
                                            <TableCell align="center">Beneficiary GrowniverseId</TableCell>
                                            <TableCell align="center">Beneficiary Name</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {packagePaymentDetail.beneficiaryId}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {packagePaymentDetail.beneficiaryGrowniverseId}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {packagePaymentDetail.beneficiaryName}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ justifyContent: "space-between", gap: "10px" }}>
                                                        <Button style={{ border: "1px solid green", background: "green", color: "white" }} onClick={() => handelbeneficiaryRequestAction(packagePaymentDetail.beneficiaryId, 1)}>Approve Beneficiary</Button><br /><br /><br />
                                                        <Button style={{ border: "1px solid red", background: "red", color: "white" }} onClick={() => handelbeneficiaryRequestAction(packagePaymentDetail.beneficiaryId, 2)}>Reject Beneficiary</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                </Table>
                            </TableContainer>
                                </div>
                            </section>
                        </>
                    )
                }
            </section>
        </>
    );
};

export default PackagePaymentRequest;
