import c1 from "../Photos/Courses/Growniverse/c1.jpg"
import c2 from "../Photos/Courses/Growniverse/c2.png"
import c3 from "../Photos/Courses/Growniverse/c3.png"
import c4 from "../Photos/Courses/Growniverse/c4.png"
import c5 from "../Photos/Courses/Growniverse/c5.png"
import c6 from "../Photos/Courses/Growniverse/c6.png"
import c7 from "../Photos/Courses/Growniverse/c7.png"
import c8 from "../Photos/Courses/Growniverse/c8.png"
import c9 from "../Photos/Courses/Growniverse/c9.png"
import c10 from "../Photos/Courses/Growniverse/c10.png"
import c11 from "../Photos/Courses/Growniverse/c11.png"
import c12 from "../Photos/Courses/Growniverse/c12.png"
import c13 from "../Photos/Courses/Growniverse/c13.png"
import c14 from "../Photos/Courses/Growniverse/c14.png"
import c15 from "../Photos/Courses/Growniverse/c15.png"
import c16 from "../Photos/Courses/Growniverse/c16.png"
import c17 from "../Photos/Courses/Growniverse/c17.png"
import c18 from "../Photos/Courses/Growniverse/c18.png"
import c19 from "../Photos/Courses/Growniverse/c19.png"
import c20 from "../Photos/Courses/Growniverse/c20.png"
import c21 from "../Photos/Courses/Growniverse/c21.png"
import c22 from "../Photos/Courses/Growniverse/c22.png"
import c23 from "../Photos/Courses/Growniverse/c23.png"
import c24 from "../Photos/Courses/Growniverse/c24.png"
import c25 from "../Photos/Courses/Growniverse/c25.png"
import c26 from "../Photos/Courses/Growniverse/c26.png"
import c27 from "../Photos/Courses/Growniverse/c27.png"
import c28 from "../Photos/Courses/Growniverse/c28.png"
import c29 from "../Photos/Courses/Growniverse/c29.png"

const courseImage = [
    { "courseId": 1, "posterLink": c1 },
    { "courseId": 2, "posterLink": c2 },
    { "courseId": 3, "posterLink": c3 },
    { "courseId": 4, "posterLink": c4 },
    { "courseId": 5, "posterLink": c5 },
    { "courseId": 6, "posterLink": c6 },
    { "courseId": 7, "posterLink": c7 },
    { "courseId": 8, "posterLink": c8 },
    { "courseId": 9, "posterLink": c9 },
    { "courseId": 10, "posterLink": c10 },
    { "courseId": 11, "posterLink": c11 },
    { "courseId": 12, "posterLink": c12 },
    { "courseId": 13, "posterLink": c13 },
    { "courseId": 14, "posterLink": c14 },
    { "courseId": 15, "posterLink": c15 },
    { "courseId": 16, "posterLink": c16 },
    { "courseId": 17, "posterLink": c17 },
    { "courseId": 18, "posterLink": c18 },
    { "courseId": 19, "posterLink": c19 },
    { "courseId": 20, "posterLink": c20 },
    { "courseId": 21, "posterLink": c21 },
    { "courseId": 22, "posterLink": c22 },
    { "courseId": 23, "posterLink": c23 },
    { "courseId": 24, "posterLink": c24 },
    { "courseId": 25, "posterLink": c25 },
    { "courseId": 26, "posterLink": c26 },
    { "courseId": 27, "posterLink": c27 },
    { "courseId": 28, "posterLink": c28 },
    { "courseId": 29, "posterLink": c29 },
];

export default courseImage;