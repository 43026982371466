import axios from "axios";
const packageJson = require('../../package.json');
const createSignature = () => {
    const secretKey = "5d928cd2-77d1-4415-abbb-4f225f40ee65"
    return secretKey;
}

export const WithDrawRequest = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.put(packageJson.apiUrl+"Account/WithDrawRequest", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const WithDrawRequestAction = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl+"Account/WithDraw", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const paymentRequest = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.put(packageJson.apiUrl+"Account/PackageRequest", userData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const paymentRequestAction = async (userData: any,userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl+"Account/PackageActivation", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const heighestEarnerOfWeek = async (userInformationId : number = 0) => {
    
    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl+"Account/heighestEarnerOfWeek", {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const getAccountDetails = async (userInformationId: number, showDetail:number = 1) => {

    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl + "Account/AccountDetail?UserInformationId=" + userInformationId+"&ShowDetail="+showDetail, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const getAccountWallet = async (userData: any,userInformationId : number = 0) => {
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl + "Account/Wallet", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const actionBeneficiary = async (userData: any,userInformationId : number = 0) => {
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl + "Account/ActionBeneficiary", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}

export const addBeneficiary = async (userData: any,userInformationId : number = 0) => {
    const signature = createSignature();
    const response = await axios.put(packageJson.apiUrl + "Account/AddBeneficiary", userData, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature,
            'X-Growniverse-UserInformationId': userInformationId
        },
    });
    return response.data;
}