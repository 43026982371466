import "../assets/css/package.css"
import { useEffect, useRef, useState } from "react"
import CourseInfoWidget from "../components/widgets/CourseInfoWidget"
import SocialShareWidget from "../components/widgets/SocialShareWidget"
import { Alert, Button, Input } from "@mui/material"
import Payment from "./Payment"
import { getUserNameByGId } from "../handler/LoginAndSingupHandler"
import { addBeneficiary, actionBeneficiary } from "../handler/accountHandler"
import packagePoster from "../Common/PackagePoster.js";

const Package = () => {
    const [template, setTemplate] = useState("package");
    const [addBeneficiaryShow, setAddBeneficiaryShow] = useState(false);
    const [loginUserName, setLoginUserName] = useState("");
    const [loginNameError, setloginNameError] = useState("");
    const [beneficiaryAdded, setBeneficiaryAdded] = useState(0);
    const [sponsorGrowniverseId, SetSponsorGrowniverseId] = useState("");
    const [userInformationId, setUserInformationId] = useState("");
    const [beneficiaryUserInformationId, setBeneficiaryUserInformationId] = useState("");
    const getUserNameByGID = async (event: any) => {
        var growniverseid = event.target.value;
        setBeneficiaryUserInformationId(growniverseid);
        if (growniverseid !== sponsorGrowniverseId) {
            if (growniverseid.length > 0) {
                var response = await getUserNameByGId(growniverseid);
                if (response.success) {
                    setLoginUserName(response.data.fullName);
                    setloginNameError("");
                }
                else {
                    setloginNameError("Invalid Growniverse ID");
                    setLoginUserName("");
                }
            }
        }
        else {
            setloginNameError("The Sponsor is already a Beneficiary, so you can't add them again.");
        }
    }
    const [packages, setPackages] = useState([
        {
            "packageId": 0,
            "packageName": "",
            "packageDescription": "",
            "packagePrice": 12000,
            "discountedPrice": 8500,
            "price": 6400
        }
    ]);
    const [packageDetailData, setPackageDetailPage] = useState({
        "packageId": 0,
        "packageName": "",
        "packageDescription": "Coming Soon",
        "price": 6400
    })
    const runOnce = useRef(false);

    const packageDetails = (pack: any) => {
        if (pack) {
            setTemplate("packageDetail")
            setPackageDetailPage(pack);
            window.scrollTo(0, 0);
        }
    };

    const CancelBeneficiary = async () => {
        var beneficiaryResponse = await actionBeneficiary({
            "beneficiaryId": beneficiaryAdded,
            "isAccepted": false
        });
        if (beneficiaryResponse.statusCode === 200) {
            window.location.reload();
        }
    }

    const addBeneficiaryAction = async () => {
        var beneficiaryResponse = await addBeneficiary({
            "userInformationId": userInformationId,
            "benificiaryGrowniverseId": beneficiaryUserInformationId
        });
        if (beneficiaryResponse.statusCode === 200) {
            window.location.reload();
        }
    }

    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            if (userProfile.status > 3) {
                window.location.href = "/dashboard";
            }
            setUserInformationId(userProfile.userInformationId);
            SetSponsorGrowniverseId(userProfile.sponsorGrowniverseId);
            setBeneficiaryAdded(userProfile.beneficiaryAdded);
            var storedPackages = localStorage.getItem("packages");
            var packagesData = JSON.parse(storedPackages ?? "");
            setPackages(packagesData);
            runOnce.current = true;
        }
    }, [runOnce])
    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);

        // Add one day to the date correctly
        date.setDate(date.getDate() + 1);

        // Get the day, month name, and year
        const day = date.getDate(); // Automatically adjusts for the new day
        const monthName = date.toLocaleString("en-US", { month: "long" }); // Get full month name
        const year = date.getFullYear();
        // Combine date and time
        var formattedDateTime = `${day} ${monthName} ${year}`;
        return <span>Hurry, offer valid till {formattedDateTime}!</span>;
    };

    return <>
        {
            template === "package" ?
                <section style={{ background: "#343434", padding: "20px" }}>
                    <div className="container" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        <div className="row">
                            {packages.map((pack) =>
                                <div className="col-md-4 col-sm-6 headerBackground" style={{ borderRadius: "20px", padding: "10px" }}>
                                    <div className="mb-md-0" style={{ height: "auto" }}>
                                        <img className="img-fluid" src={packagePoster.find(data => data.packageId === pack.packageId)?.poster} alt={packagePoster.find(data => data.packageId === pack.packageId)?.poster} style={{ width: "100%", height: "auto", borderRadius: "20px" }} />
                                    </div><br />
                                    <div style={{ color: "black", background: "#899ba1", width: "100%", display: "inline-grid", borderRadius: "50px" }}>
                                        <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>
                                            {pack.packageName}
                                        </h2>
                                    </div><br /><br />
                                    <div style={{ alignContent: "center", color: "black", background: "#919191", width: "100%", height: "200px", display: "inline-grid", borderRadius: "20px" }}>
                                        <p style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderRadius: "10px", margin: "5px", background: "black" }}>
                                            <span style={{ fontSize: "20px" }}>
                                                <span style={{ color: "white" }}>MRP : </span>
                                                <span style={{ color: "white", textDecorationLine: "line-through" }}>
                                                    <span>₹{pack.packagePrice}</span>
                                                </span>
                                            </span>
                                            <span style={{ fontSize: "25px" }}>
                                                <span style={{ color: "white" }}>DISCOUNT : </span>
                                                <span style={{ color: "white", textDecorationLine: "line-through" }}>
                                                    <span>₹{pack.discountedPrice}</span>
                                                </span>
                                            </span>
                                        </p>
                                        <p style={{ display: "flex", justifyContent: "center", padding: "10px", borderRadius: "10px", margin: "5px" }}>
                                            <span style={{ fontSize: "30px" }}>
                                                <span style={{ color: "green" }}>
                                                    <span className="fw-bold text-success ms-3" style={{ fontSize: "20px", background: "white", borderRadius: "20px", padding: "5px 20px 5px 20px" }}>
                                                        LIMITED TIME DEAL : <b style={{ fontSize: "25px" }}>₹{pack.price}</b></span>
                                                </span>
                                            </span>
                                        </p>
                                        <div>
                                            <button style={{ borderRadius: "30px", background: "#343434", padding: "10px" }} onClick={() => packageDetails(pack)}>
                                                <b style={{ color: "#Daa425", fontSize: "20px" }}>EXPLORE MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {
                        beneficiaryAdded !== 0 ?
                            <>
                                <div>
                                    <span style={{ color: "green" }}>Beneficiary successfully added.</span><br />
                                    <Button style={{ padding: "10px", margin: "10px", borderRadius: "10px", fontSize: "10px" }} onClick={CancelBeneficiary}>Cancel Beneficiary</Button>
                                </div>
                            </>
                            :
                            <>
                                <Button style={{ padding: "10px", margin: "10px", borderRadius: "10px", fontSize: "10px" }} onClick={() => { setAddBeneficiaryShow(true) }}>Add Beneficiary</Button>
                            </>
                    }
                    {
                        addBeneficiaryShow && (
                            <section style={{ height: "100%", background: "rgba(0, 0, 0, 0.9)", position: "fixed", width: "100%", top: 0, zIndex: 1000, left: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button style={{ position: "fixed", top: 0, right: 0 }} onClick={() => {
                                    setAddBeneficiaryShow(false);
                                    setLoginUserName("");
                                    setloginNameError("")
                                }}><i className="fa fa-times" aria-hidden="true" style={{ fontSize: "20px" }}></i></Button>
                                <div className="headerBackground" style={{
                                    height: "30%", width: "70%", left: "5%", borderRadius: "20px", overflow: "auto", display: "block", padding: "20px",

                                }}>
                                    <Input type="text" name='GrowniverseId' placeholder="Growniverse ID" onChange={() => { setloginNameError("") }} onBlur={getUserNameByGID} disabled={loginUserName.length > 0} required /><br />
                                    <div>
                                        {
                                            loginUserName.length > 0 && (<>
                                                <span
                                                    style={{
                                                        color: '#2ecc71',
                                                    }}
                                                >
                                                    {loginUserName}
                                                </span>
                                            </>)
                                        }
                                        {loginNameError.length > 0 && (
                                            <h6 style={{ color: 'red', textAlign: 'center', paddingTop: "5px" }}>
                                                <Alert severity="error" variant="outlined">
                                                    {loginNameError}
                                                </Alert>
                                            </h6>
                                        )}
                                    </div>
                                    {
                                        loginUserName.length > 0 && (
                                            <Button onClick={addBeneficiaryAction} style={{ display: 'block', color: "lightgray", margin: '10px auto', border: '1px solid black', borderRadius: '10px' }}>
                                                Add Beneficiary
                                            </Button>
                                        )
                                    }
                                </div>
                            </section>
                        )
                    }
                </section>
                :
                <>
                    {
                        template === "packageDetail" ?
                            <>
                                <div className='container' style={{ padding: "10px" }}>
                                    <div style={{ padding: "10px" }}>
                                        <Button onClick={() => { (setTemplate("package")) }} style={{ borderRadius: "20px", color: "white", background: "#343434" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-7 col-xl-8'>
                                            <img className="img-fluid" src={packagePoster.find(data => data.packageId === packageDetailData.packageId)?.poster} alt={packagePoster.find(data => data.packageId === packageDetailData.packageId)?.poster} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                            <h4 className="news-title mb-2">{packageDetailData.packageName}</h4>
                                            <div className=''>
                                                <div className='widgets'>
                                                    <div className="widget widget-price">
                                                        <h6 className="widget-title">Price</h6>
                                                        <div className="widget-content" style={{ padding: "20px", border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#f9f9f9" }}>
                                                            <h4 style={{ color: "#ff6f00", fontWeight: "bold", textAlign: "center", marginBottom: "10px" }}>
                                                                🎉 Limited-Time Offer: Get Extra 2% Assured Cashback!
                                                            </h4>
                                                            <h6 style={{ color: "#333", lineHeight: "1.6", textAlign: "center", marginBottom: "20px" }}>
                                                                Pay Using Your Wallet and Get 2% Cashback on Your Purchase.<br />
                                                                <strong style={{ color: "#ff4500" }}>{DateTimeDisplay(new Date().toString())}</strong><br />
                                                                Shop Now and Save More!
                                                            </h6>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <Button
                                                                    onClick={() => { setTemplate("payment") }}
                                                                    style={{
                                                                        borderRadius: "25px",
                                                                        backgroundColor: "#007bff",
                                                                        color: "white",
                                                                        padding: "15px",
                                                                        fontWeight: "bold",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    className="btn btn-primary"
                                                                >
                                                                    <span style={{ fontSize: "18px", fontWeight: "bold", marginRight: "10px" }}>Get {packageDetailData.packageName}</span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border mb-4">
                                                <h6 className="text-dark px-4 py-2 bg-light mb-0">Description</h6>
                                                <div className="p-4 border-top">
                                                    <CourseInfoWidget packageId={packageDetailData.packageId} />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className='widgets'>
                                                    <div className="widget widget-price">
                                                        <h6 className="widget-title">Price</h6>
                                                        <div className="widget-content">
                                                            <Button onClick={() => { (setTemplate("payment")) }} style={{ borderRadius: "20px", background: "black", color: "white", padding: "10px" }} className="btn btn-primary" >
                                                                Get {packageDetailData.packageName}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <SocialShareWidget />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </> :
                            <>
                                {
                                    template === "payment" ?
                                        <>
                                            <section style={{ background: "#343434" }}>
                                                <div style={{ padding: "10px" }}>
                                                    <Button onClick={() => { (setTemplate("packageDetail")) }} style={{ borderRadius: "20px", color: "#343434", background: "white" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                                </div>
                                                <Payment packageDetailData={packageDetailData} IsUpgrade={false} />
                                            </section>
                                        </>
                                        : <></>
                                }
                            </>
                    }
                </>
        }
    </>
};

export default Package;