import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { getAdminPannel, updateUser } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';

const UpdateUserByAdmin = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    // Handle page change
    const handleChangePage = (event:any,newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getUserInformation = async () => {
        setLoader(true);
        var response = await getAdminPannel(4);
        if (response.statusCode === 200) {
            const userDetails = response.data.adminPannel.userDetails;
            setUserInformation(userDetails);
            setFilterData(userDetails);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getUserInformation();
            runValue.current = true;
        }
    }, [runValue]);
    const [userInformation, setUserInformation] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "emailId": "string",
            "phoneNumber": "string",
            "status": 1,
            "registrationDate": "2025-01-07T06:23:53.095Z",
            "dateOfBirth": "2025-01-07",
            "packageId": 0,
            "packageName": ""
        }
    ]);

    const [filterData, setFilterData] = useState([
        {
            "userInformationId": 0,
            "growniverseId": "string",
            "fullName": "string",
            "emailId": "string",
            "phoneNumber": "string",
            "status": 1,
            "registrationDate": "2025-01-07T06:23:53.095Z",
            "dateOfBirth": "2025-01-07",
            "packageId": 0,
            "packageName": ""
        }
    ]);
    // Slice data for current page
    const paginatedData = filterData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const UpdatePackageSection = (statement: any) => {
        setPackageSection(true);
        setPackageUserDetail(statement);
        setNewPackageId(statement.packageId)
    }
    const [packageUserDetail, setPackageUserDetail] = useState({
        "userInformationId": 0,
        "growniverseId": "",
        "fullName": "",
        "emailId": "",
        "phoneNumber": "",
        "status": 1,
        "registrationDate": "",
        "dateOfBirth": "",
        "packageId": 0,
        "packageName": ""
    })
    const [packageSection, setPackageSection] = useState(false);
    const packages = [
        { packageId: 0, packageName: "No Package" },
        { packageId: 1, packageName: "Elite Package" },
        { packageId: 2, packageName: "Basic Package" },
        { packageId: 3, packageName: "Advanced Package" },
        { packageId: 4, packageName: "Silver Package" },
        { packageId: 5, packageName: "Gold Package" },
        { packageId: 6, packageName: "Platinum Package" },
    ];

    const handleChange = (event: any) => {
        setNewPackageId(parseInt(event.target.value))
    };

    const UpdatePackage = async () => {
        setLoader(true);
        setPackageSection(false);
        const newPackage = packages.find(data => data.packageId === newPackageId);
        await updateUser({
            "userInformationId": packageUserDetail.userInformationId,
            "newPackageId": newPackage?.packageId ?? 1,
            "newPackageName": newPackage?.packageName ?? "Basic Package"
        });
        getUserInformation();
        setLoader(false);
    }
    const [newPackageId, setNewPackageId] = useState(0);
    const search = (event: any) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = userInformation.filter((data) =>
            data.fullName.toLowerCase().includes(searchTerm) ||
            data.growniverseId.toLowerCase().includes(searchTerm)
        );
        setFilterData(filtered);
        setPage(0); // Reset to first page
    }
    return (
        <>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <section className='headerBackground'>
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <TableContainer style={{ background: "white" }}>
                                <h3 style={{ textAlign: "center" }}>Growniverse Users</h3>
                                <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Input placeholder='Search' onChange={search} />
                                </div>
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="center">UserInformationId</TableCell>
                                            <TableCell align="center">GrowniverseId</TableCell>
                                            <TableCell align="center">FullName</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align='center'>Package</TableCell>
                                            <TableCell align='center'>Update Package</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {paginatedData.map((statement, index) => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {statement.userInformationId}
                                                </TableCell>
                                                <TableCell align="center">{statement.growniverseId}</TableCell>
                                                <TableCell align="center">{statement.fullName}</TableCell>
                                                <TableCell align="center">{statement.status === 0 ? "Email Not Confirmed" : statement.status === 1 ? "Email Confirmed" : statement.status === 2 ? "No Package" : statement.status === 3 ? "In Progress" : statement.status === 4 ? "KYC Pending" : statement.status === 5 ? "KYC Requested" : statement.status === 6 ? "Active" : "InActive"}</TableCell>
                                                <TableCell align="center">{statement.packageName}</TableCell>
                                                <TableCell align="center"><Button style={{ border: "1px solid #343434" }} onClick={() => { UpdatePackageSection(statement) }} disabled={packageSection}>Update Package</Button></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20]}
                                    component="div"
                                    count={userInformation.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    </section>
                )
            }
            {
                packageSection && (
                    <>
                        <section>
                            <div style={{ background: "#3434", height: "100%", width: "100%", position: "fixed", left: "0", top: "0" }}>
                                <div style={{ background: "white", width: "100%", height: "70%", margin: "10% 0 10% 0", padding: "10% 10% 10% 10%" }}>
                                    <Table>
                                        <TableHead>
                                            <TableCell style={{ fontWeight: "bold" }}>GrowniverseId</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>{packageUserDetail.growniverseId}</TableCell>
                                        </TableHead>
                                        <TableHead>
                                            <TableCell style={{ fontWeight: "bold" }}>User Name</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>{packageUserDetail.fullName}</TableCell>
                                        </TableHead>
                                        <TableHead>
                                            <TableCell style={{ fontWeight: "bold" }}>Current Package</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>{packageUserDetail.packageName}</TableCell>
                                        </TableHead>
                                        <TableHead>
                                            <TableCell style={{ fontWeight: "bold" }}>New Package</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>
                                                <div>
                                                    <select
                                                        id="packageDropdown"
                                                        value={newPackageId}
                                                        onChange={handleChange}
                                                        style={{ padding: "10px", border: "1px solid #343434", background: "#B4EF9D", borderRadius: "10px" }}
                                                    >
                                                        <option value="" disabled>
                                                            Select a package
                                                        </option>
                                                        {packages.map((pkg) => (
                                                            <option key={pkg.packageId} value={pkg.packageId}>
                                                                {pkg.packageName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </TableCell>
                                        </TableHead>
                                    </Table><br />
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Button style={{ border: "2px solid #343434", borderRadius: "10px" }} onClick={() => { setPackageSection(false) }}>Close</Button>
                                        <Button style={{ border: "2px solid #343434", borderRadius: "10px" }} onClick={() => { UpdatePackage() }}>Update Package</Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    );
};

export default UpdateUserByAdmin;
