import React, { useEffect, useRef, useState } from 'react';
import InnerBanner from '../components/InnerBanner';
import AboutSection from '../components/AboutSection';
import AboutFeature from '../components/AboutFeature';
import Header from '../components/Header';
import { dashboard } from '../handler/LoginAndSingupHandler';

const ContactPage = () => {
    const [statistics, setStatistics] = useState({
        students: 0,
        courses: 0,
        teachers: 0,
        awards: 0,
    });
    const runOnce = useRef(false);
    const dashboardApi = async () => {
        var response = await dashboard();
        if (response.statusCode === 200) {
            setStatistics(response.data.dashboard.statistics);
        }
    }
    useEffect(() => {
        if (runOnce.current === false) {
            dashboardApi();
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        <Header />
        <InnerBanner pageName='About Us' />
        <AboutSection statistics={statistics} />
        <AboutFeature />
    </>
};

export default ContactPage;