import elite from "../Photos/Package/Elite_Package.png"
import basic from "../Photos/Package/Basic_Package.png"
import advanced from "../Photos/Package/Advanced_Package.png"
import silver from "../Photos/Package/Silver_Package.png"
import Gold from "../Photos/Package/Gold_Package.png"
import Platinum from "../Photos/Package/Platinum_Package.png"
const PackagePoster = [
    {
        "packageId":1,
        "poster":elite
    },
    {
        "packageId":2,
        "poster":basic
    },
    {
        "packageId":3,
        "poster":advanced
    },
    {
        "packageId":4,
        "poster":silver
    },
    {
        "packageId":5,
        "poster":Gold
    },
    {
        "packageId":6,
        "poster":Platinum
    }
]

export default PackagePoster;