import { useEffect, useRef, useState } from 'react';
import "./BackgroundImages.css"
import growniverseImage from "../Photos/Logo/growniverse-appicon-transparent.svg";
import { MoonLoader } from 'react-spinners';
import "../components/WelcomeSection.css"
import Slider from 'react-slick';
import { dashboard } from '../handler/LoginAndSingupHandler';
import courseImage from "src/Common/CourseImage";

const CourseList = () => {
    const [loader, setLoader] = useState(true);
    const [activeFilter, setActiveFilter] = useState(6);
    const [packages, setPackages] = useState([
        {
            courseId: 0,
            courseName: "",
            packageId: 0
        }
    ]);

    const categories = [
        'Elite Package',
        'Basic Package',
        'Advanced Package',
        'Silver Package',
        'Gold Package',
        'Platinum Package'
    ];

    const handleFilter = (category: any) => {
        setActiveFilter(category);
    };
    const getCourses = async () => {
        var response = await dashboard(2);
        if (response.statusCode === 200) {
            setPackages(response.data.dashboard.courses);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getCourses();
            runValue.current = true;
        }
    }, [runValue]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <section className="space-ptb headerBackground">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-title text-center">
                            <h2 style={{ color: "white" }}>Achieve More with Our Premium Courses</h2>
                            <h5 style={{ color: "White" }}>
                                Designed by Professionals
                            </h5>
                            <p style={{ color: "White" }}>
                                'Learning Today, Leading Tomorrow: Be the Change You Want to See.'
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 position-relative">
                        <div className="filters-group mb-2 mb-4">
                            {categories.map((category, index) => (
                                <button
                                    key={category}
                                    className={`btn-filter ${activeFilter === index + 1 ? 'active' : ''}`}
                                    onClick={() => handleFilter(index + 1)}
                                    style={{ color: "white" }}
                                >
                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                </button>
                            ))}
                        </div>
                        <div className='space-ptb achievers-section BgSpace' style={{ borderStyle: "inset", borderColor: "white" }}>
                            {
                                loader ?
                                    <div style={{ display: "block", height: "100%" }}>
                                        <div
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={growniverseImage}
                                                alt="Loading..."
                                                width="100px" // Adjust size as needed
                                                style={{
                                                    position: "absolute",
                                                    animation: "spin 5s linear infinite", // Apply the spin animation
                                                }}
                                            />
                                            <MoonLoader size="120" color="#f5b45f" />
                                        </div>
                                    </div>
                                    :
                                    <div className='container'>
                                        <Slider {...settings}>
                                            {packages.filter(data => data.packageId === activeFilter)
                                                .map((course) => (
                                                    <>
                                                        <div
                                                            key={course.courseId}
                                                            className='slide-item px-3'
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                padding: "0",
                                                                position: "relative",
                                                                display: "block",
                                                            }}
                                                        >
                                                            <div
                                                                className="categories"
                                                            >
                                                                <img className="img-fluid" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} style={{
                                                                    width: "100%",
                                                                    height: "200px",
                                                                }} />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginTop: "-25px",
                                                                    backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                                                                    width: "100%",
                                                                    textAlign: "left",
                                                                    color: "black"
                                                                }}
                                                            >
                                                                <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </Slider>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseList;
