import React, { useEffect, useRef, useState } from "react";
import "./Design/Dashboard.css";

interface InnerBannerProps {
  account: {
    todayIncome: number;
    sevenDayIncome: number;
    monthlyIncome: number;
    totalIncome: number;
    unPaidBalance: number;
    sevenDayTurnover: number;
    monthlyTurnover: number;
    totalTurnover: number;
    totalPaid: number;
    adminOutstandingPayments: number,
    taxPayment: number
  };
  pageName: string;
  isAdmin: boolean;
}

const Income: React.FC<InnerBannerProps> = ({ account, pageName, isAdmin }) => {
  const validIds = ["G10021", "G10023", "G10024", "G100252", "G100253", "G100225", "G100211", "G10029"];
  const [showTurnover, setShowTurnover] = useState(false);
  const [counters, setCounters] = useState({
    todayIncome: 0,
    sevenDayIncome: 0,
    monthlyIncome: 0,
    totalIncome: 0,
    unPaidBalance: 0,
    sevenDayTurnover: 0,
    monthlyTurnover: 0,
    totalTurnover: 0,
    totalPaid: 0,
    adminOutstandingPayments: 0,
    taxPayment: 0
  });

  const animatedCounters = useRef({
    todayIncome: Math.random() * account.todayIncome, // Start from a random value
    sevenDayIncome: Math.random() * account.sevenDayIncome,
    monthlyIncome: Math.random() * account.monthlyIncome,
    totalIncome: Math.random() * account.totalIncome,
    unPaidBalance: Math.random() * account.unPaidBalance,
    sevenDayTurnover: Math.random() * account.sevenDayTurnover,
    monthlyTurnover: Math.random() * account.monthlyTurnover,
    totalTurnover: Math.random() * account.totalTurnover,
    totalPaid: Math.random() * account.totalPaid,
    adminOutstandingPayments: Math.random() * account.adminOutstandingPayments,
    taxPayment: Math.random() * account.taxPayment,
  });

  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const targetValues = account;
    const duration = 2000; // Animation duration in milliseconds
    const startTime = performance.now();

    const animateCounters = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // Ensure progress doesn't exceed 1

      // Calculate interpolated values for each counter
      const newValues = {
        todayIncome: animatedCounters.current.todayIncome + progress * (targetValues.todayIncome - animatedCounters.current.todayIncome),
        sevenDayIncome: animatedCounters.current.sevenDayIncome + progress * (targetValues.sevenDayIncome - animatedCounters.current.sevenDayIncome),
        monthlyIncome: animatedCounters.current.monthlyIncome + progress * (targetValues.monthlyIncome - animatedCounters.current.monthlyIncome),
        totalIncome: animatedCounters.current.totalIncome + progress * (targetValues.totalIncome - animatedCounters.current.totalIncome),
        unPaidBalance: animatedCounters.current.unPaidBalance + progress * (targetValues.unPaidBalance - animatedCounters.current.unPaidBalance),
        sevenDayTurnover: animatedCounters.current.sevenDayTurnover + progress * (targetValues.sevenDayTurnover - animatedCounters.current.sevenDayTurnover),
        monthlyTurnover: animatedCounters.current.monthlyTurnover + progress * (targetValues.monthlyTurnover - animatedCounters.current.monthlyTurnover),
        totalTurnover: animatedCounters.current.totalTurnover + progress * (targetValues.totalTurnover - animatedCounters.current.totalTurnover),
        totalPaid: animatedCounters.current.totalPaid + progress * (targetValues.totalPaid - animatedCounters.current.totalPaid),
        adminOutstandingPayments: animatedCounters.current.adminOutstandingPayments + progress * (targetValues.adminOutstandingPayments - animatedCounters.current.adminOutstandingPayments),
        taxPayment: animatedCounters.current.taxPayment + progress * (targetValues.taxPayment - animatedCounters.current.taxPayment),
      };

      // Update the state with the new interpolated values
      setCounters({
        todayIncome: Math.round(newValues.todayIncome),
        sevenDayIncome: Math.round(newValues.sevenDayIncome),
        monthlyIncome: Math.round(newValues.monthlyIncome),
        totalIncome: Math.round(newValues.totalIncome),
        unPaidBalance: Math.round(newValues.unPaidBalance),
        sevenDayTurnover: Math.round(newValues.sevenDayTurnover),
        monthlyTurnover: Math.round(newValues.monthlyTurnover),
        totalTurnover: Math.round(newValues.totalTurnover),
        totalPaid: Math.round(newValues.totalPaid),
        adminOutstandingPayments: Math.round(newValues.adminOutstandingPayments),
        taxPayment: Math.round(newValues.taxPayment),
      });

      // Continue the animation until progress reaches 1
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateCounters);
      }
    };

    animationRef.current = requestAnimationFrame(animateCounters);

    const storedProfile = localStorage.getItem("userProfile");
    const userProfile = JSON.parse(storedProfile ?? "");

    if (validIds.includes(userProfile.growniverseId)) {
      setShowTurnover(true);
    }

    // Cleanup the animation frame on unmount
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [account]);
  const formatRupees = (amount: number) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };
  return (
    <section style={{ padding: "20px", background: "#343434" }}>
      {pageName === "dashboard" && (
        <>
          <div className="row todayIncome income">
            <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
              <h3 style={{ color: "white" }}>Today's {isAdmin ? "Turnover" : "Income"}</h3>
            </div>
            <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
              <h2 style={{ color: "white" }}>{formatRupees(counters.todayIncome)}</h2>
            </div>
          </div>
          <div className="row sevenIncome income">
            <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
              <h3 style={{ color: "white" }}>7 Day's {isAdmin ? "Turnover" : "Income"}</h3>
            </div>
            <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
              <h2 style={{ color: "white" }}>{formatRupees(counters.sevenDayIncome)}</h2>
            </div>
          </div>
          <div className="row monthIncome income">
            <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
              <h3 style={{ color: "white" }}>Monthly {isAdmin ? "Turnover" : "Income"}</h3>
            </div>
            <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
              <h2 style={{ color: "white" }}>{formatRupees(counters.monthlyIncome)}</h2>
            </div>
          </div>
        </>
      )}
      <div className="row totalIncome income">
        <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
          <h3 style={{ color: "white" }}>Total {isAdmin ? "Turnover" : "Income"}</h3>
        </div>
        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
          <h2 style={{ color: "white" }}>{formatRupees(counters.totalIncome)}</h2>
        </div>
      </div>
      <div className="row unpaidIncome income">
        <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
          <h3 style={{ color: "white" }}>Un-Paid Balance</h3>
        </div>
        <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
          <h2 style={{ color: "white" }}>{formatRupees(counters.unPaidBalance)}</h2>
        </div>
      </div>
      {
        isAdmin && (
          <>
            <div className="row unpaidIncome income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>Outstanding Payments</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.adminOutstandingPayments)}</h2>
              </div>
            </div>
            <div className="row unpaidIncome income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>Paid Money</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.totalPaid)}</h2>
              </div>
            </div>
            <div className="row unpaidIncome income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>Tax Payment</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.taxPayment)}</h2>
              </div>
            </div>
          </>
        )
      }
      {
        (!isAdmin && showTurnover) && (
          <>
            <div className="row sevenTurnover income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>7 Day's Turnover</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.sevenDayTurnover)}</h2>
              </div>
            </div>
            <div className="row monthlyTurnover income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>Monthly Turnover</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.monthlyTurnover)}</h2>
              </div>
            </div>
            <div className="row totalTurnover income">
              <div className="col-8" style={{ alignItems: "center", display: "flex" }}>
                <h3 style={{ color: "white" }}>Total Turnover</h3>
              </div>
              <div className="col-4" style={{ alignItems: "center", display: "flex", justifyContent: "right" }}>
                <h2 style={{ color: "white" }}>{formatRupees(counters.totalTurnover)}</h2>
              </div>
            </div>
          </>
        )
      }
    </section>
  );
};

export default Income;
