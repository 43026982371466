import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./BackgroundImages.css"
import elite from "../Photos/Package/Elite_Package.png"
import basic from "../Photos/Package/Basic_Package.png"
import advanced from "../Photos/Package/Advanced_Package.png"
import silver from "../Photos/Package/Silver_Package.png"
import Gold from "../Photos/Package/Gold_Package.png"
import Platinum from "../Photos/Package/Platinum_Package.png"
const PackageList = () => {
  const [activeFilter, setActiveFilter] = useState('All');
  const packages = [
    {
      title: 'Elite Package',
      freepackage: '',
      rating: 4.9,
      reviews: 234,
      category: 'Elite',
      img: elite,
    },
    {
      title: 'Basic Package',
      freepackage: '+ Elite Package Free',
      rating: 4.8,
      reviews: 578,
      category: 'Basic',
      img: basic,
    },
    {
      title: 'Advanced Package',
      freepackage: '+ Basic Package Free',
      rating: 4.6,
      reviews: 18,
      category: 'Advanced',
      img: advanced,
    },
    {
      title: 'Silver package',
      freepackage: '+ Advanced Package Free',
      rating: 4.9,
      reviews: 97,
      category: 'Silver',
      img: silver,
    },
    {
      title: 'Gold Package',
      freepackage: '+ Silver Package Free',
      rating: 4.8,
      reviews: 172,
      category: 'Gold',
      img: Gold,
    },
    {
      title: 'Platinum Package',
      freepackage: '+ Gold Package Free',
      rating: 5.0,
      reviews: 48,
      category: 'Platinum',
      img: Platinum,
    }
  ];

  const categories = [
    'All',
    'Elite',
    'Basic',
    'Advanced',
    'Silver',
    'Gold',
    'Platinum'
  ];

  const handleFilter = (category: any) => {
    setActiveFilter(category);
  };

  return (
    <section className="space-ptb BgSpace">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-title text-center">
              <h2 style={{ color: "white" }}>The Greatest Choice Of Package's</h2>
              <p>
                'The Best Investment You can Ever Make is in YourSelf, The More you Learn, The More you Earn.'
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 position-relative">
            <div className="filters-group mb-2 mb-4">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`btn-filter ${activeFilter === category ? 'active' : ''}`}
                  onClick={() => handleFilter(category)}
                  style={{ color: "white" }}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
            <div className="my-shuffle-container grid-4 shuffle">
              {packages
                .filter(
                  (course) =>
                    activeFilter === 'All' || course.category === activeFilter
                )
                .map((course, index) => (
                  <div
                    key={index}
                    className="grid-container"
                    data-groups={`[${course.category}]`}
                  >
                    <div className="course grid-item">
                      <div className="course-img">
                        <img
                          className="img-fluid"
                          src={course.img}
                          alt='Growniverse'
                        />
                      </div>
                      <div className="course-info" style={{background:"#dfe8e8"}}>
                        <div className="course-title">
                          <Link to={'#'}><b style={{fontSize:"20px"}}>{course.title}</b></Link>
                        </div>
                        <div>
                          <Link to={'#'} style={{color:"#f2a407",fontSize:"15px"}}>{course.freepackage}</Link>
                        </div>
                        <div className="course-rate-price" style={{borderTop:"1px solid black"}}>
                          <div className="rating">
                            <span style={{ background: "green" }}>{course.rating}⭐</span>
                          </div>
                          <div className="rating">
                            <span style={{ background: "#343434" }}><i className="fa fa-clock"></i> 24X7 Learning Experience</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PackageList;
