import React from 'react';

const stringToColor = (name: string): string => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // Generates a unique HSL color
    return color;
};

const stringToInitials = (name: string): string => {
    const initials = name
        .split(' ')
        .map((word) => word[0]?.toUpperCase() || '')
        .join('');
    return initials.substring(0, 3); // Use at most 2 initials
};

const Avatar = ({ name, size }: { name: string; size: number }) => {
    const backgroundColor = stringToColor(name);
    const initials = stringToInitials(name);

    const avatarStyle: React.CSSProperties = {
        backgroundColor,
        color: 'white',
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: size / 2.5,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    };

    return <div style={avatarStyle}>{initials}</div>;
};

export default Avatar;
