import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import { AnySoaRecord } from 'dns';
interface InnerBannerProps {
    accountStatement: {
        accountStatementId: number,
        credit: number,
        debit: number,
        dateTime: string,
        growniverseId:string,
        fullName:string,
        type:number
    }[],
    growniversId:string;
}

const AccountStatements: React.FC<InnerBannerProps> = ({ accountStatement,growniversId }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const fundType = [
        {
            fundType : 1,
            fundName : "Income"
        },
        {
            fundType : 2,
            fundName : "WithDraw"
        },
        {
            fundType : 3,
            fundName : "Transfer"
        },
        {
            fundType : 4,
            fundName : "Remit"
        },
        {
            fundType : 5,
            fundName : "Beneficiary Income"
        },
        {
            fundType : 6,
            fundName : "Admin Transfer"
        },
        {
            fundType : 7,
            fundName : "Champion Fund"
        },
        {
            fundType : 8,
            fundName : "Supreme Fund"
        },
        {
            fundType : 9,
            fundName : "Legend Fund"
        },
        {
            fundType : 10,
            fundName : "Co-Founder Fund"
        },
        {
            fundType: 11,
            fundName: "Cashback"
        },
        {
            fundType: 12,
            fundName: "Package Purchased"
        }
    ]
    // Handle page change
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Slice data for current page
    const paginatedData = accountStatement.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);
    
        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
    
        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
    
        // Combine date and time
        const formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
    
        return <div>{formattedDateTime}</div>;
    };
    return (
        <section className='headerBackground'>
            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                <TableContainer style={{ background: "white" }}>
                    <h3 style={{ textAlign: "center" }}>Account Statements</h3>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell align="center">Sr.No</TableCell>
                                <TableCell align="center">Tr.ID</TableCell>
                                <TableCell align="center">Credit</TableCell>
                                <TableCell align="center">Debit</TableCell>
                                <TableCell align="center">Allocation</TableCell>
                                <TableCell align="center">Fund</TableCell>
                                <TableCell align="center">DateTime</TableCell>
                            </TableRow>
                        </TableHead>
                        {paginatedData.map((statement,index) => (
                            <TableBody key={statement.accountStatementId}>
                                <TableRow>
                                    <TableCell align="center">
                                        {index+1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {statement.accountStatementId}
                                    </TableCell>
                                    <TableCell style={{ color: "green" }} align="center">{statement.credit > 0 ? "₹" : ""}{statement.credit}</TableCell>
                                    <TableCell style={{ color: "red" }} align="center">{statement.debit > 0 ? "₹" : ""}{statement.debit}</TableCell>
                                    <TableCell align="center">{statement.growniverseId ? statement.growniverseId === "R1999" ? statement.fullName : growniversId === statement.growniverseId ? "Self" : statement.fullName + " (" + statement.growniverseId + ")" : ""}</TableCell>
                                    <TableCell align="center">{fundType.find(data=>data.fundType === statement.type)?.fundName}</TableCell>
                                    <TableCell align="center">{DateTimeDisplay(statement.dateTime)}</TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={accountStatement.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </div>
        </section>
    );
};

export default AccountStatements;
