import React from 'react';
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
const AgreementSection = () => {
    return (
        <section>
            <div style={{ padding: "10px" }}>
                <h5>Affiliate Agreement Growniverse</h5>
                <p style={{ color: "black" }}>
                    This Affiliate Agreement ("Agreement") is made between Growniverse ("Company") and ("Affiliate").
                </p>
                <p style={{ color: "black" }}>
                    "This agreement specifies that enrollee join Growniverse for educational purposes only,not with unrealistic earnings expectations.<br />Income is directly tied to sales performance, requiring hard work. Growniverse prioritizes education upfront, promoting learning and skill-building as primary objectives."
                </p>
                <h6>Article 1: Definitions</h6>
                <p>
                    1.1 "Affiliate Program" means the program established by the Company Growniverse to promote its digital learning courses through affiliates.<br />
                    1.2 "Digital Courses" means the digital learning courses and educational materials provided by the Company.<br />
                    1.3 "Affiliate Link" means the unique URL or Growniverse ID provided by the Company to the Affiliate to promote the Digital learning Courses.<br />
                    1.4 "Commission" means the payment earned by the Affiliate for each sale of the Digital Courses made through the Affiliate Link or Growniverse ID.<br />
                </p>
                <h6>Article 2: Affiliate Program Terms</h6>
                <p>
                    2.1 The Company grants the Affiliate a non-exclusive, non-transferable license to promote the Digital Courses through the Affiliate Program.<br />
                    2.2 The Affiliate agrees to promote the Digital Courses only through the Affiliate Link provided by the Company.<br />
                    2.3 The Affiliate shall not engage in any deceptive or misleading marketing practices, including but not limited to:
                    <ul style={{ listStyleType: "square" }}>
                        <li>Using fake or misleading testimonials</li>
                        <li>Making false or unsubstantiated claims about the Digital Courses provided by Growniverse</li>
                        <li>Using spam or unsolicited email marketing</li>
                    </ul>
                    2.4 The Affiliate shall comply with all applicable laws and regulations, including but not limited to:
                    <ul style={{ listStyleType: "square" }}>
                        <li>Federal Trade Commission (FTC) guidelines on affiliate marketing</li>
                        <li>General Data Protection Regulation (GDPR) guidelines on data protection</li>
                        <li>Consumer Protection Act By Ministry of Consumer Affairs </li>
                    </ul>
                </p>
                <h6>Article 3: Commission Structure</h6>
                <p>
                    3.1 The Company shall pay the Affiliate a Commission of [COMMISSION RATE]% of the sale price for each Digital Course sold through the Affiliate Link.<br />
                    3.2 The Commission shall be paid on a [PAYMENT FREQUENCY] basis, within [PAYMENT TERMS] days of the sale.<br />
                    3.3 Request for The Commission Shall be paid only after the 24 Hours into your bank account due to the Cancellation Policy.
                </p>
                <h6>Article 4: Term and Termination</h6>
                <p>
                    4.1 The term of this Agreement shall commence on the Effective Date and continue until terminated by either party with [NUMBER] days' written notice.<br />
                    4.2 Upon termination, the Affiliate shall immediately cease promoting the Digital Courses and remove all Affiliate Links from their website or marketing materials.
                </p>
                <h6>Article 5: Confidentiality</h6>
                <p>
                    5.1 The Affiliate shall keep confidential all information and materials provided by the Company, including but not limited to:
                    <ul style={{ listStyleType: "square" }}>
                        <li>Digital Course content</li>
                        <li>Marketing materials</li>
                        <li>Sales data</li>
                    </ul>
                    5.2 The Affiliate shall not disclose any confidential information to any third party without the prior written consent of the Company.
                </p>
                <h6>Article 6: Indemnification</h6>
                <p>
                    6.1 The Affiliate shall indemnify and hold harmless the Company, its officers, directors, employees, and agents from and against any claims, damages, losses, and expenses arising from:
                    <ul>
                        <li>The Affiliate's breach of this Agreement</li>
                        <li>The Affiliate's negligence or misconduct</li>
                        <li>Any claims of intellectual property infringement</li>
                    </ul>
                </p>
                <h6>Article 7: Governing Law</h6>
                <p>
                    7.1 This Agreement shall be governed by and construed in accordance with the laws of [STATE/COUNTRY].<br />
                    7.2 Any disputes arising out of or related to this Agreement shall be resolved through [DISPUTE RESOLUTION PROCESS].
                </p>
                <h6>Article 8: Entire Agreement</h6>
                <p>
                    8.1 This Agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous agreements or understandings.
                </p>
                <h6>Article 9: Amendments</h6>
                <p>
                    9.1 The Company reserves the right to modify or update this Agreement at any time without prior notice.<br />
                    9.2 If Any Affiliate Unfollow any terms and conditions provided by Growniverse. Then He or She will be Permanently Terminated From the Affiliate Link Provided By Growniverse.
                </p>
                <h6>Article 10: Acceptance</h6>
                <p>
                    10.1 By participating in the Affiliate Program, the Affiliate acknowledges that they have read, understood, and agree to abide by the terms and conditions of this Agreement.
                </p>
                <p>
                    By Clicking on the link, the parties acknowledge that they have read, understood, and agree to abide by the terms and conditions of this Agreement.
                </p>
                <p>
                NAME__
                </p>
                <img src={GrowniverseLogo} width={"20%"} alt='Growniverse'/>
                <p>
                    GROWNIVERSE<br />
                    A UNIVERSE WHERE YOU GROW
                </p>
            </div>
        </section>
    );
};

export default AgreementSection;
