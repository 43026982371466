import "./Design/Dashboard.css"
import { useEffect, useRef, useState } from "react";
import "./Design/Dashboard.css"
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";


const AffiliateLink = () => {
    const [growniverseId, setGrowniverseId] = useState("");
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setGrowniverseId(userProfile.growniverseId);
            runOnce.current = true;
        }
    }, [runOnce]);
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    const handleShare = async () => {
        try {
            const shareData = {
                title: 'Growniverse',
                text: 'Register Link :- ',
                url: link,
            };
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                alert('Web Share API is not supported in your browser.');
            }
        } catch (error) {
        }
    };
    const link = `https://growniverse.com/?affiliatelink=${growniverseId}`;
    const handleCopy = () => {
        navigator.clipboard.writeText(link).then(() => {
        }).catch((error) => {
            console.error('Error copying link:', error);
        });
    };
    return <>
        <>
            <section className="headerBackgroundReverse" style={{ padding: "30px", background: "" }}>
            <h3 style={{ textAlign: "center", color: "white", background: "black", padding: "10px", borderRadius: "8px" }}>Affiliate Link</h3>
                <div style={{ background: "white", borderRadius: "10px",padding:"10px" }}>
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold", fontSize: "15px" }}>{link}</span>
                    </div>
                    <Table>
                        <TableHead>
                            <TableCell align="center"><Button onClick={handleCopy} style={{ gap: "5px" }}>Copy <i className="fas fa-copy"></i></Button></TableCell>
                            <TableCell align="center"><Button onClick={handleShare} style={{ gap: "5px" }}>Share<i className="fas fa-share-alt"></i></Button></TableCell>
                        </TableHead>
                    </Table>
                </div>
            </section>
        </>
    </>
};

export default AffiliateLink;