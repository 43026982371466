import { useEffect, useRef, useState } from "react";
import { getAccountDetails, getAccountWallet } from "../handler/accountHandler";
import { Alert, Button, Input } from "@mui/material";
import { getUserNameByGId } from "../handler/LoginAndSingupHandler";
import { GetPackages } from "../handler/coursehandler";
import growniverseImage from "../Photos/Logo/growniverse-appicon-transparent.svg";
import { MoonLoader } from "react-spinners";
import UserLoader from "../Dashboard/UserLoader";
import AccountStatements from "../components/AccountStatements";
import AdminAccountStatements from "./AdminAccountStatements";

const AdminWallet = () => {
    const [loader, setLoader] = useState(true);
    const [loaderOtp, setLoaderOtp] = useState(false);
    const [fullLoader, setFullLoader] = useState(true);
    const [validateOtp, setValidateOtp] = useState(false);
    const [otpEnter, setOtpEnter] = useState(NaN);
    const handelOtpEnter = (event: any) => {
        if (event.target.value.length <= 5) {
            setOtpEnter(event.target.value);
        }
    }
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        status: 0
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const [accountData, setAccountData] = useState({
        accountId: 0,
        unPaidBalance: 0,
        totalIncome: 0,
        todayIncome: 0,
        sevenDayIncome: 0,
        monthlyIncome: 0,
        directIncome: 0,
        passiveIncome: 0,
        transactions: {
            transactionId: 0,
            amount: 0,
            status: 0,
            transactionTime: "2025-12-09T19:11:34.225688"
        },
        accountStatements: [],
    });
    const [localWithDraw, setLocalWithDraw] = useState(false);
    const getAccountDetail = async (userInformationId: number) => {
        var response = await getAccountDetails(userInformationId);
        if (response.statusCode === 200) {
            setAccountData(response.data.account);
            if (response.data.account.transactions) {
                setLocalWithDraw(true)
            }
        }
        setFullLoader(false);
    }
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    const [packages, setPackages] = useState([
        {
            "packageId": 0,
            "packageName": "CustomAmount",
            "packageDescription": "",
            "packagePrice": 12000,
            "discountedPrice": 8500,
            "price": 6400
        }
    ]);
    const getPackage = async () => {
        const response = await GetPackages();
        if (response.statusCode === 200) {
            setPackages([...response.data.packages]);
        }
        setLoader(false);
    }
    const [loginUserName, setLoginUserName] = useState("");
    const [loginNameError, setloginNameError] = useState("");
    const [loginUserValid, setLoginUserValid] = useState(false);
    const [transferAmount, setTransferAmount] = useState(NaN);
    const [transferError, setTransferError] = useState("");
    const [receiverGrowniverseId, setReceiverGrowniverseId] = useState(NaN);
    const [walletId, setWalletId] = useState(NaN);

    const getUserNameByGID = async (event: any) => {
        var growniverseid = event.target.value;
        setReceiverGrowniverseId(growniverseid);
        if (growniverseid.toUpperCase() !== userProfile.growniverseId.toUpperCase()) {
            if (growniverseid.length > 0) {
                var response = await getUserNameByGId(growniverseid);
                if (response.success) {
                    setLoginUserName(response.data.fullName);
                    setloginNameError("");
                    setLoginUserValid(true);
                    await getPackage();
                }
                else {
                    setloginNameError("Invalid Growniverse ID");
                    setLoginUserName("");
                    setLoginUserValid(false);
                }
            }
        }
        else {
            setloginNameError("You can't send funds to your own Growniverse Account.")
        }
    }
    const validateAmount = () => {

    }
    const handleTransferAmount = (event: any) => {
        setTransferError("")
        var amount = event.target.value;
        if (amount.length <= 5) {
            setTransferAmount(parseInt(amount, 10));
        }
    }
    const onTransfer = async () => {
        if (transferAmount < accountData.unPaidBalance) {
            setValidateOtp(true);
            setLoaderOtp(true);
            var response = await getAccountWallet({
                "userInformationId": userProfile.userInformationId,
                "receiverGrowniverseId": receiverGrowniverseId,
                "amount": transferAmount
            });
            if (response.statusCode === 200) {
                setWalletId(response.data.walletId);
                setTime(90);
            }
            else {
                setValidateOtp(false);
            }
            setLoaderOtp(false);
        }
        else {
            setTransferError("Insufficient funds in the wallet.")
        }
    }

    const [otpInvalid, setOtpInvalid] = useState("");
    const [Successfully, setSuccessfully] = useState(false);
    const validateRequest = async () => {
        setLoaderOtp(true);
        setOtpInvalid("");
        var response = await getAccountWallet({
            "type": 2,
            "walletRequestId": walletId,
            "otp": otpEnter,
            "statementType": selectedFund
        });
        if (response.statusCode === 200) {
            accountData.unPaidBalance -= transferAmount;
            setAccountData({ ...accountData });
            setSuccessfully(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
        else {
            setOtpInvalid(response.message);
        }
        setLoaderOtp(false);
    }

    const fundType = [
        {
            fundType : 6,
            fundName : "Admin Transfer"
        },
        {
            fundType : 7,
            fundName : "Champion Fund"
        },
        {
            fundType : 8,
            fundName : "Supreme Fund"
        },
        {
            fundType : 9,
            fundName : "Legend Fund"
        },
        {
            fundType : 10,
            fundName : "Co-Founder Fund"
        }
    ]

    const [selectedFund, setSelectedFund] = useState(6);
    const initialTime = 60; // Countdown starts from 60 seconds
    const [time, setTime] = useState(initialTime);
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    useEffect(() => {
        if (time > 0) {
            const timerId = setTimeout(() => setTime(time - 1), 1000);
            return () => clearTimeout(timerId);
        }
        return undefined; 
    }, [time]);
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserProfile(userProfile);
            getAccountDetail(userProfile.userInformationId);
            runOnce.current = true;
        }
    }, [runOnce]);
    return <>
        {
            fullLoader ?
                <UserLoader />
                :
                <section style={{ padding: "10px", display: "block" }}>
                    <div
                        className="headerBackgroundReverse"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px",
                            border: "1px solid #1E4849",
                            borderRadius: "8px",
                            width: "100%",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <h2 style={{ fontFamily: "Georgia, serif", color: "white", marginBottom: "15px" }}>
                            Wallet Balance
                        </h2>
                        <div style={{
                            background: "white",
                            padding: "15px 25px",
                            borderRadius: "5px",
                            color: "#22878B",
                            fontFamily: "Georgia, serif",
                            fontSize: "1.5em",
                            boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {formatRupees(accountData.unPaidBalance)}
                        </div>
                    </div>
                    {
                        !Successfully ?
                            <div
                                className="headerBackground"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "10px",
                                    border: "1px solid #1E4849",
                                    borderRadius: "8px",
                                    width: "100%",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    marginTop: "10px"
                                }}>
                                <h2 style={{ fontFamily: "Georgia, serif", color: "white", marginBottom: "15px" }}>
                                    Transfer
                                </h2>
                                {
                                    !validateOtp ?
                                        <div style={{ background: "white", padding: "10px", borderRadius: "10px", display: "flex", flexDirection: "column", width: "90%" }}>
                                            <Input type="text" name='GrowniverseId' placeholder="Growniverse ID" onChange={() => { setloginNameError("") }} onBlur={getUserNameByGID} disabled={loginUserName.length > 0} required />
                                            {
                                                loginUserName.length > 0 && (<>
                                                    <span
                                                        style={{
                                                            color: '#2ecc71',
                                                            textAlign: 'center',
                                                            padding: '10px',
                                                            fontFamily: 'Arial, sans-serif',
                                                            fontSize: '1.2em',
                                                            borderRadius: '5px',
                                                            display: 'inline-block',
                                                            background: '#ecf9f1',
                                                            margin: "5px"
                                                        }}
                                                    >
                                                        {loginUserName}
                                                    </span>
                                                </>)
                                            }
                                            {loginNameError.length > 0 && (
                                                <h6 style={{ color: 'red', textAlign: 'center', paddingTop: "5px" }}>
                                                    <Alert severity="error" variant="outlined">
                                                        {loginNameError}
                                                    </Alert>
                                                </h6>
                                            )}
                                            {
                                                loginUserValid && (
                                                    loader ?
                                                        <>
                                                            <div
                                                                style={{
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    padding: "50px"
                                                                }}
                                                            >
                                                                <img
                                                                    src={growniverseImage}
                                                                    alt="Loading..."
                                                                    width="100px" // Adjust size as needed
                                                                    style={{
                                                                        position: "absolute",
                                                                        animation: "spin 5s linear infinite", // Apply the spin animation
                                                                    }}
                                                                />
                                                                <MoonLoader size="120" color="#f5b45f" />
                                                            </div>
                                                        </> :
                                                        <>
                                                            <Input type="number" placeholder="Amount To Tranfer" name="transferAmount" onChange={handleTransferAmount} value={transferAmount} onBlur={validateAmount}
                                                                style={{border:"1px dotted black",padding:"5px"}}
                                                                />
                                                            <div style={{
                                                                width: '100%', /* Adjust the width as needed */
                                                                overflowX: 'auto',
                                                                background: 'white',
                                                                padding: '10px',
                                                                borderRadius: '10px',
                                                            }}>
                                                                {
                                                                    fundType.map(fund => (<>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedFund(fund.fundType);
                                                                                setTransferError("");
                                                                            }}
                                                                            style={
                                                                                selectedFund === fund.fundType ? {
                                                                                    color: '#3C898C',
                                                                                    textAlign: 'center',
                                                                                    padding: '10px',
                                                                                    fontFamily: 'Arial, sans-serif',
                                                                                    fontSize: '1em',
                                                                                    borderRadius: '5px',
                                                                                    display: 'inline-block',
                                                                                    background: '#ecf9f1',
                                                                                    margin: "5px",
                                                                                    width: "80%",
                                                                                    fontWeight: "bold"
                                                                                } :
                                                                                    {
                                                                                        color: '#48d5db',
                                                                                        textAlign: 'center',
                                                                                        padding: '10px',
                                                                                        fontFamily: 'Arial, sans-serif',
                                                                                        fontSize: '1em',
                                                                                        borderRadius: '5px',
                                                                                        display: 'inline-block',
                                                                                        background: '#ecf9f1',
                                                                                        margin: "5px",
                                                                                        width: "80%"
                                                                                    }
                                                                            }>
                                                                            {
                                                                                fund.fundType === selectedFund && (
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        top: '50%',
                                                                                        left: '0',
                                                                                        transform: 'translateY(-50%)',
                                                                                        height: '20px',
                                                                                        width: '20px',
                                                                                        borderRadius: '50%',
                                                                                        border: '5px solid #3C898C'
                                                                                    }}>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <span>
                                                                                {fund.fundName}<br />
                                                                            </span>
                                                                        </Button>
                                                                    </>))
                                                                }
                                                            </div>
                                                            <Button onClick={onTransfer} hidden={transferAmount === 0 || Number.isNaN(transferAmount)} style={{
                                                                background: "#1E4849",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "5px",
                                                                padding: "10px 20px",
                                                                margin: "10px",
                                                                cursor: "pointer",
                                                                fontFamily: "Georgia, serif"
                                                            }}>
                                                                Transfer
                                                            </Button>
                                                            {
                                                                transferError.length > 0 && (
                                                                    <>
                                                                        <Alert severity="info">{transferError}</Alert>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                )
                                            }
                                        </div>
                                        :
                                        <>
                                            {
                                                loaderOtp ?
                                                    <>
                                                        <div
                                                            style={{
                                                                height: "100%",
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                padding: "50px"
                                                            }}
                                                        >
                                                            <img
                                                                src={growniverseImage}
                                                                alt="Loading..."
                                                                width="100px" // Adjust size as needed
                                                                style={{
                                                                    position: "absolute",
                                                                    animation: "spin 5s linear infinite", // Apply the spin animation
                                                                }}
                                                            />
                                                            <MoonLoader size="120" color="#f5b45f" />
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div style={{ background: "white", textAlign: 'center', padding: '20px', border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', maxWidth: '300px', margin: '0 auto' }}>
                                                            <h2 style={{ color: '#4CAF50' }}>OTP Validation</h2>
                                                            <Input
                                                                type="number"
                                                                value={otpEnter}
                                                                onChange={handelOtpEnter}
                                                                placeholder="Enter OTP"
                                                                style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
                                                            />
                                                            {
                                                                otpInvalid.length > 0 && (
                                                                    <Alert severity="error" style={{ padding: "5px", marginBottom: "10px" }}>{otpInvalid}</Alert>
                                                                )
                                                            }
                                                            <Button disabled={otpEnter.toString().length < 5} onClick={validateRequest} style={
                                                                otpEnter.toString().length < 5 ? { padding: '10px 20px', backgroundColor: '#A1F0A4', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }
                                                                    : { padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }
                                                            }>
                                                                Validate OTP
                                                            </Button><br /><br />
                                                            <div style={{ textAlign: 'center' }}>
                                                                {
                                                                    time === 0 ?
                                                                        <>
                                                                            <Button onClick={onTransfer}>Resend Otp</Button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            Please wait
                                                                            <span style={{ color: "#ffab00" }}> {formatTime(time)} </span>
                                                                            for Resend
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                            :
                            <div
                                className="headerBackgroundReverse"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "10px",
                                    border: "1px solid #1E4849",
                                    borderRadius: "8px",
                                    width: "100%",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    marginTop: "10px"
                                }}
                            >
                                <p style={{ color: 'green', marginTop: '10px' }}>
                                    Transaction Successful!
                                </p>
                            </div>
                    }<br/>
                    <AdminAccountStatements accountStatement={accountData.accountStatements} />
                </section>
        }
    </>
};

export default AdminWallet;