import React from 'react';
import "../components/WelcomeSection.css"
import growniverseIntro from '../Videos/GrowniverseIntro.mp4';
import '../Dashboard/Design/Dashboard.css'
const UserLoaderVideo = () => {
    return (
        <>
            <div
                style={{
                    position: "absolute",
                    background: "black",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden"
                }}
            >
                <video className="loaderVideo" autoPlay loop muted>
                    <source src={growniverseIntro} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </>
    );
};

export default UserLoaderVideo;