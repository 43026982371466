import "./Design/Dashboard.css"
import "../components/WelcomeSection.css";
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { GetPackages } from "../handler/coursehandler";
import Payment from "../Package/Payment";
import Avatar from "../components/Avatar";
import packagePoster from "../Common/PackagePoster.js";


interface InnerBannerProps {
    userProfile: {
        fullName: string,
        growniverseId: string,
        packageName: string,
        isAdmin: boolean,
        profileImage: string,
        packageId: number,
        packageRequest: boolean
    };
    totalIncome:number;
}
const DashboardProfile: React.FC<InnerBannerProps> = ({ userProfile,totalIncome }) => {
    const [designation, setDesignation] = useState("");
    const validIds = ["G10029", "G10023","G10024","G100252","G100253","G100225","G100211"];
    const designations = {
        Champion: "Champion",
        Supreme: "Supreme",
        Legend: "Legend",
        CoFounder: "Co-Founder",
        Founder: "Founder",
    };
    const [template, setTemplate] = useState("package");
    const [showUpgradePackage, setShowUpgradePackage] = useState(false);
    const [loader, setLoader] = useState(true);
    const [packages, setPackages] = useState([
        {
            "packageId": 0,
            "packageName": "",
            "packageDescription": "",
            "postarLink": "",
            "packagePrice": 12000,
            "discountedPrice": 8500,
            "price": 6400
        }
    ]);
    const runOnce = useRef(false);
    const [packageDetailData, setPackageDetailPage] = useState({
        "packageId": 0,
        "packageName": "",
        "packageDescription": "Coming Soon",
        "price": 6400
    })
    const UpgradePackage = async () => {
        setShowUpgradePackage(true);
        await getPackage();
    }

    const getPackage = async () => {
        const response = await GetPackages();
        if (response.statusCode === 200) {
            setPackages([...response.data.packages]);
        }
        setLoader(false);
    }
    useEffect(()=>{
        if (runOnce.current == false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            if (totalIncome >= 100000) {
                setDesignation(designations.Champion);
            }
            else if (totalIncome >= 500000) {
                setDesignation(designations.Supreme);
            }
            else if (totalIncome >= 1000000) {
                setDesignation(designations.Legend);
            }
            else if (totalIncome >= 2000000 || validIds.includes(userProfile.growniverseId)) {
                setDesignation(designations.CoFounder);
            }
            else if(userProfile.growniverseId === "G10021")
            {
                setDesignation(designations.Founder);
            }
            else {
                setDesignation(userProfile.packageName);
            }
            runOnce.current = true
        }
    },[runOnce])
    return <>
        <section style={{ background: "#343434", width: "100%" }}>
            <div className="container dashboardProfile">
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    {
                        userProfile.profileImage && (
                            <img src={`data:image/jpeg;base64,${userProfile.profileImage}`} alt="Decoded from Base64" style={{ width: "100px", height: "100px", borderRadius: "60px", border: "2.5px solid black" }} />
                        )
                    }
                    {
                        !userProfile.profileImage && (
                            <Avatar name={userProfile.fullName} size={100} />
                        )
                    }

                </div>
                <div style={{ textAlign: "center" }}>

                    <div style={{ color: "black", border: "2px solid black", background: "#899ba1", borderRadius: "30px", width: "300px", display: "inline-grid", paddingBottom: "5px" }}>
                        <h2>{userProfile.fullName}</h2>
                        <h3>{userProfile.growniverseId}</h3>
                        {
                            userProfile.isAdmin && (
                                <>
                                    <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>Growniverse Founder</h3>
                                </>
                            )
                        }
                        <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>{designation}</h3>
                        {
                            (userProfile.packageId !== 6 && window.location.pathname === "/profile") && (
                                <>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {
                                            userProfile.packageRequest ? <>
                                                <div
                                                    className="marquee"
                                                    style={{
                                                        // backgroundColor: 'rgb(28, 105, 100)',
                                                        color: 'rgb(17, 49, 47)',
                                                        border: "1px solid rgb(93, 126, 124)",
                                                        padding: '10px 20px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '14px',
                                                        borderRadius: '10px',
                                                        margin: '4px',
                                                        cursor: 'pointer',
                                                        transition: '0.3s',
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    <span>Package Upgrade Under Process...</span>
                                                </div>
                                            </> : <>
                                                <Button
                                                    onClick={() => { UpgradePackage() }}
                                                    style={{
                                                        background: 'linear-gradient(to right, #075854, #1E6662,#367572)',
                                                        color: 'white',
                                                        padding: '15px 30px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '16px',
                                                        margin: '4px 2px',
                                                        transition: '0.4s',
                                                        cursor: 'pointer',
                                                        borderRadius: '20px',
                                                        border: 'none',
                                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                                    }}
                                                >
                                                    Upgrade Package
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
        {
            (showUpgradePackage) && (
                <>
                    {
                        <section style={{ height: "100%", background: "rgba(0, 0, 0, 0.9)", position: "fixed", width: "100%", top: 0, zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button style={{ position: "fixed", top: 0, right: 0 }} onClick={() => { setShowUpgradePackage(false) }}><i className="fa fa-times" aria-hidden="true" style={{ fontSize: "20px" }}></i></Button>
                            <div style={{ background: "white", height: "80%", width: "90%", left: "5%", borderRadius: "20px", overflow: "auto" }}>
                                {
                                    template === "package"
                                        ?
                                        <section style={{ background: "#343434", padding: "20px" }}>
                                            <div className="container" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                <div className="row">
                                                    {packages.map((pack) =>
                                                        <>
                                                            {
                                                                pack.packageId > userProfile.packageId && (
                                                                    <div className="col-md-4 col-sm-6 headerBackground" style={{ borderRadius: "20px", padding: "10px" }}>
                                                                        <div className="mb-md-0" style={{ height: "auto" }}>
                                                                        <img className="img-fluid" src={packagePoster.find(data=>data.packageId === pack.packageId)?.poster} alt={packagePoster.find(data=>data.packageId === pack.packageId)?.poster} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                                                        </div><br />
                                                                        <div style={{ color: "black", background: "#899ba1", width: "100%", display: "inline-grid", borderRadius: "50px" }}>
                                                                            <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>
                                                                                {pack.packageName}
                                                                            </h2>
                                                                        </div><br /><br />
                                                                        <div style={{ alignContent: "center", color: "black", background: "#919191", width: "100%", height: "100px", display: "inline-grid", borderRadius: "10px" }}>
                                                                            <p style={{ display: "flex", justifyContent: "space-between", padding: "0 40px 0 40px" }}>
                                                                                <span style={{ fontSize: "20px" }}>
                                                                                    <span style={{ color: "black", textDecorationLine: "line-through" }}>
                                                                                        <span>₹{pack.packagePrice}</span>
                                                                                    </span>
                                                                                </span>
                                                                                <span style={{ fontSize: "25px" }}>
                                                                                    <span style={{ color: "black", textDecorationLine: "line-through" }}>
                                                                                        <span>₹{pack.discountedPrice}</span>
                                                                                    </span>
                                                                                </span>
                                                                                <span style={{ fontSize: "30px" }}>
                                                                                    <span style={{ color: "red" }}>
                                                                                        <span>₹{pack.price}</span>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                            <div>
                                                                                <Button style={{ borderRadius: "30px", background: "#343434" }} onClick={() => {
                                                                                    setPackageDetailPage(pack);
                                                                                    setTemplate("payment");
                                                                                }
                                                                                }>
                                                                                    <b style={{ color: "#Daa425" }}>Upgrade to {pack.packageName}</b>
                                                                                    {/* <b style={{ color: "#Daa425" }}> Coming Soon...</b> */}
                                                                                </Button><br />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        <>
                                            {
                                                template === "payment" && (<>
                                                    <section style={{ background: "#343434" }}>
                                                        <div style={{ padding: "10px" }}>
                                                            <Button onClick={() => { (setTemplate("package")) }} style={{ borderRadius: "20px", color: "#343434", background: "white" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                                        </div>
                                                        <Payment packageDetailData={packageDetailData} IsUpgrade={true}/>
                                                    </section>
                                                </>)
                                            }
                                        </>
                                }
                            </div>
                        </section>
                    }
                </>
            )
        }
    </>
};

export default DashboardProfile;