import { Button } from "@mui/material";
import { useRef, useState, useCallback, useEffect } from "react";
import { GetUserCourses } from "../../handler/coursehandler";
import elite from "../../Photos/Package/Elite_Package.png"
import basic from "../../Photos/Package/Basic_Package.png"
import advanced from "../../Photos/Package/Advanced_Package.png"
import silver from "../../Photos/Package/Silver_Package.png"
import Gold from "../../Photos/Package/Gold_Package.png"
import Platinum from "../../Photos/Package/Platinum_Package.png"
import Customized from "../../Photos/Package/Customized_Package.png"
import growniverseImage from "../../Photos/Logo/growniverse-appicon-transparent.svg";
import { MoonLoader } from 'react-spinners';
import Learning from "./Learning";
import CourseOrders from "./CourseOrders";
import courseImage from "src/Common/CourseImage";
import "../../Design/Responsive.css"

const MyCourses = () => {
    const playerRef = useRef<HTMLVideoElement | null>(null);
    const courseStartLearning = async (course: any) => {
        setShowModule("learning");
        setCourseLearning(course);
    }
    const [showModule, setShowModule] = useState("package");
    const [courses, setCourses] = useState([
        {
            courseId: 1,
            courseName: "",
            courseDescription: "",
            percentage: 0,
            images: "",
            packageId: 0,
            courseVideoCount: 0
        }
    ]); // State to hold the list of courses
    const [loader, setLoader] = useState(true); // State to manage loading status
    const runOnce = useRef(false); // Ref to ensure effect runs only once
    const [packages, setPackages] = useState([
        {
            "packageId": 1,
            "packageName": "Elite Package",
            "packageDescription": "An introductory course package for beginners.",
            "posterLink": elite
        },
        {
            "packageId": 2,
            "packageName": "Basic Package",
            "packageDescription": "An introductory course package for beginners.",
            "posterLink": basic
        },
        {
            "packageId": 3,
            "packageName": "Advanced Package",
            "packageDescription": "A comprehensive course package for advanced learners.",
            "posterLink": advanced
        },
        {
            "packageId": 4,
            "packageName": "Silver Package",
            "packageDescription": "A specialized course package with extra resources.",
            "posterLink": silver
        },
        {
            "packageId": 5,
            "packageName": "Gold Package",
            "packageDescription": "A premium course package with exclusive content.",
            "posterLink": Gold
        },
        {
            "packageId": 6,
            "packageName": "Platinum Package",
            "packageDescription": "The ultimate course package with all available features.",
            "posterLink": Platinum
        }
    ]);
    const MyCourses = async (packgaeId: number) => {
        setLoader(true)
        const response = await GetUserCourses(packgaeId);
        if (response.statusCode === 200) {
            setCourses(response.data.courses);
        }
        setShowModule("courses")
        setLoader(false);
    };
    const [courseLearning, setCourseLearning] = useState({
        courseId: 1,
        courseName: "",
        courseDescription: "",
        status: "",
        images: "",
        packageId: 0
    });
    const [purchaseOrder, setPurchaseOrder] = useState(false);
    const [packageId, setPackageId] = useState(0);
    const [growniverseId, setGrowniverseId] = useState("");
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setPackageId(userProfile.packageId);
            setGrowniverseId(userProfile.growniverseId);
            runOnce.current = true;
            setLoader(false);
        }
    }, [runOnce])
    return (
        <>
            <style>
                {`
                    .list-group-item {
                        transition: background-color 0.3s ease;
                    }

                    .list-group-item:hover {
                        background-color: #f1f1f1;
                    }
                    
                    .course-container {
                        border: 5px solid #343434;
                        padding: 20px;
                    }
                    
                    .course-header {
                        text-align: center;
                        color: white;
                    }

                    .badge {
                        background-color: #17a2b8;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 20px;
                    }
                `}
            </style>
            <section style={{ padding: "20px" }} className="headerBackground">
                {
                    loader && (
                        <>
                            <div
                                style={{
                                    background: "white",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "50px"
                                }}
                            >
                                <img
                                    src={growniverseImage}
                                    alt="Loading..."
                                    width="100px" // Adjust size as needed
                                    style={{
                                        position: "absolute",
                                        animation: "spin 5s linear infinite", // Apply the spin animation
                                    }}
                                />
                                <MoonLoader size="120" color="#f5b45f" />
                            </div>
                        </>
                    )
                }
                {
                    !loader && (
                        <>
                            {showModule === "package" && (<>
                                <div className="course-container" style={{ background: "#F6F6F0", padding: "20px", borderRadius: "8px" }}>
                                    <h3 style={{ textAlign: "center", color: "white", background: "black", padding: "10px", borderRadius: "8px" }}>My Courses</h3>
                                    <section style={{ background: "#3434", padding: "20px", borderRadius: "8px" }}>
                                        <div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {packages.map((pack) => (
                                                <div key={pack.packageId} style={{ background: "black", border: "1px solid #ccc", borderRadius: "8px", width: "100%", textAlign: "center", marginBottom: "20px" }}>
                                                    <Button onClick={() => MyCourses(pack.packageId)} style={{ textDecoration: "none", color: "inherit", width: "100%", display: "block" }}>
                                                        <img className="packagePosterShow" src={pack.posterLink} alt={pack.packageName} style={{ objectFit: "fill", borderRadius: "8px 8px 0px 0px" }} />
                                                        {pack.packageId <= packageId ?
                                                            <>
                                                                <Button style={{ color: "green" }}>Purchased</Button>
                                                            </> :
                                                            <Button>Explore</Button>}
                                                    </Button>
                                                </div>
                                            ))}
                                            {
                                                packageId < 6 &&
                                                (
                                                    <>
                                                        <div style={{ background: "black", border: "1px solid #ccc", borderRadius: "8px", width: "100%", textAlign: "center", marginBottom: "20px" }}>
                                                            <Button onClick={() => { }} style={{ textDecoration: "none", color: "inherit", width: "100%", display: "block" }}>
                                                                <img className="packagePosterShow" src={Customized} alt={Customized} style={{ objectFit: "fill", borderRadius: "8px 8px 0px 0px" }} />
                                                            </Button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </section>
                                </div>
                            </>)}
                            {showModule === "courses" && (<>
                                <h3 style={{ textAlign: "center", color: "white", background: "black" }}>My Courses</h3>
                                <div className="course-container" style={{ background: "#F6F6F0" }}>
                                    {(
                                        <ul className="list-group" style={{ gap: "20px" }}>
                                            <div>
                                                <Button onClick={() => { (setShowModule("package")) }} style={{ borderRadius: "20px", color: "white", background: "#343434" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                            </div>
                                            {courses.map((course) => (
                                                <>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            padding: "0",
                                                            position: "relative",
                                                            display: "block",
                                                        }}
                                                    >
                                                        <div
                                                            className="categories"
                                                        >
                                                            <img className="img-fluid posterShow" src={courseImage.find(data => data.courseId === course.courseId)?.posterLink} alt={course.images} />
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop: "-25px",
                                                                backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                                                                width: "100%",
                                                                textAlign: "left",
                                                                color: "black"
                                                            }}
                                                        >
                                                            <h2 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h2>
                                                            <p style={{ fontSize: "15px" }}>{course.courseDescription}</p>
                                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                                                                {
                                                                    (growniverseId === "G10029" 
                                                                        || growniverseId === "G10021" 
                                                                        || growniverseId === "G10023"
                                                                        || growniverseId === "G10024"
                                                                        || growniverseId === "G100225"
                                                                        || growniverseId === "G100211")
                                                                        ? <>
                                                                            {
                                                                                course.courseVideoCount > 0 ?
                                                                                    <>
                                                                                        {course.packageId <= packageId ?
                                                                                            <>
                                                                                                {
                                                                                                    course.percentage > 0 ?
                                                                                                    <Button onClick={() => { courseStartLearning(course) }} className="headerBackground" style={{ borderRadius: "20px", color: "white" }}>Resume Course ({course.percentage}%)</Button>
                                                                                                    :
                                                                                                    <Button onClick={() => { courseStartLearning(course) }} className="headerBackground" style={{ borderRadius: "20px", color: "white" }}>Start Course</Button>
                                                                                                }
                                                                                            </> :
                                                                                            <Button style={{ background: "#4187AD", color: "white", borderRadius: "10px" }}>Buy Now<i className="fa fa-shopping-cart" aria-hidden="true"></i></Button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <Button
                                                                                        style={{
                                                                                            background: "#4187AD",
                                                                                            color: "white",
                                                                                            borderRadius: "10px",
                                                                                            padding: "10px 20px",
                                                                                            border: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            transition: "background 0.3s ease",
                                                                                            gap: "10px"
                                                                                        }}
                                                                                    >
                                                                                        Coming Soon <i className="fa fa-clock" aria-hidden="true"></i>
                                                                                    </Button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {course.packageId <= packageId ?
                                                                                <>
                                                                                    <Button
                                                                                        style={{
                                                                                            background: "#4187AD",
                                                                                            color: "white",
                                                                                            borderRadius: "10px",
                                                                                            padding: "10px 20px",
                                                                                            border: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            transition: "background 0.3s ease",
                                                                                            gap: "10px"
                                                                                        }}
                                                                                    >
                                                                                        Coming Soon <i className="fa fa-clock" aria-hidden="true"></i>
                                                                                    </Button>
                                                                                </> :
                                                                                <Button style={{ background: "#4187AD", color: "white", borderRadius: "10px" }}>Buy Now<i className="fa fa-shopping-cart" aria-hidden="true"></i></Button>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div><br />
                                                </>
                                            ))}
                                            <div>
                                                <Button onClick={() => { (setShowModule("package")) }} style={{ borderRadius: "20px", color: "white", background: "#343434" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                            </div>
                                        </ul>
                                    )}
                                </div>
                            </>)}
                            {showModule === "learning" && (<>
                                <div>
                                    <Button onClick={() => { (setShowModule("courses")) }} style={{ borderRadius: "20px", color: "white", background: "#343434", marginBottom: "10px" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                </div>
                                <Learning courseId={courseLearning.courseId} />
                            </>)}

                            {
                                showModule === "customizedPackage" &&
                                <>

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                                        <Button onClick={() => { (setShowModule("package")) }} style={{ borderRadius: "20px", color: "white", background: "#343434" }}><i className="fa-solid fa-arrow-left"></i></Button>
                                        <Button onClick={() => { setPurchaseOrder(true) }} className="headerBackgroundReverse" style={{ position: "fixed", right: "20px", color: "white" }}>Orders</Button>
                                    </div>
                                    <section style={{ padding: "20px", height: "200px", background: "white", borderRadius: "5px" }}>

                                    </section>
                                </>
                            }
                        </>
                    )
                }
            </section>
            {
                purchaseOrder && <>
                    <section style={{ height: "100%", background: "rgba(0, 0, 0, 0.9)", position: "fixed", width: "100%", top: 0, zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button onClick={() => { (setShowModule("customizedPackage")); setPurchaseOrder(false); }} style={{ borderRadius: "20px", color: "white", background: "#343434", position: "fixed", top: "10px", left: "10px" }}><i className="fa-solid fa-arrow-left"></i></Button>
                        <CourseOrders />
                    </section>
                </>
            }
        </>
    );
};

export default MyCourses;
